<div *ngIf="jsonData && dataLoaded" class="main-container">
  <ng-container
    *ngTemplateOutlet="
      generateForm;
      context: { $implicit: jsonData, nivel: nivel + 1 }
    "
  ></ng-container>
</div>

<div class="main-container">
  @if(indicator && indicator['default_field'].length > 0){
    <div>
      <label class="text-900 font-medium mb-2 mr-2">Campo por defecto:
        <b>{{
          removeCampoSuffixDefaultField(defaultFieldSelected.default_field_label)
        }}</b>
        con valor
        {{ defaultFieldSelected.default_field_value }}
      </label>
    </div>
  }

  <ng-template #generateForm let-data let-nivel="nivel">
    <div form-control *ngFor="let key of getKeys(data); let index = index">
      <div *ngIf="data[key].tipo" class="mt-3">
        <div
          *ngIf="
            !(
              extractOperation(data[key].tipo) === 'SUMA' ||
              extractOperation(data[key].tipo) === 'RESTA' ||
              extractOperation(data[key].tipo) === 'MULTIPLICACION' ||
              extractOperation(data[key].tipo) === 'DIVISION'
            )
          "
        >
          <!-- Si es preview -->
          @if(preview){
            <div style="display: flex; align-items: center; gap: 3vw">
              <div
                [ngClass]="'header-' + (nivel + 1) + ' mb-4 mt-4 no-cursiva'"
                [innerHTML]="removeCampoSuffix(key)"
              ></div>
            </div>

            <ion-grid>
              <ion-row>
                <ion-col
                  *ngFor="let label of labels; let label_number = index"
                  size-xs="12"
                  size-sm="12"
                  size-md="6"
                >
                  <div>
                    <label class="text-900 font-medium mb-2 mr-2"
                      >{{ label }}
                    </label>
                    <app-input-excel
                      [preview]="true"
                      [type]="deleteSuffix(data[key].tipo)"
                      [label]="label"
                      [(value)]="data[key][label]"
                      [required]="true"
                    ></app-input-excel>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          }

          <!-- Si no es preview -->
          @if(!preview){
            <!-- Si hay campo por defecto. No mostramos el input para campo por defecto -->
            @if(defaultFieldSelected && (removeCampoSuffixDefaultField(defaultFieldSelected.default_field_label) !== removeCampoSuffixDefaultField(key))){
              <div style="display: flex; align-items: center; gap: 3vw">
                <div
                  [ngClass]="'header-' + (nivel + 1) + ' mb-4 mt-4 bold no-cursiva'"
                  [innerHTML]="removeCampoSuffix(key)"
                >
                </div>
              </div>

              <ion-grid>
                <ion-row>
                  <ion-col
                    *ngFor="let label of labels; let label_number = index"
                    size-xs="12"
                    size-sm="12"
                    size-md="6"
                  >
                    <div>
                      <label class="text-900 font-medium mb-2 mr-2"
                        >{{ label }}
                      </label>
                      <app-input-excel
                        [type]="deleteSuffix(data[key].tipo)"
                        [label]="label"
                        [(value)]="data[key][label]"
                        [required]="true"
                      ></app-input-excel>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            }

            <!-- Si no hay campo por defecto. Mostramos todos los inputs disponibles -->
            @if(indicator && indicator['default_field'] && indicator['default_field'].length === 0){
              <div style="display: flex; align-items: center; gap: 3vw">
                <div
                  [ngClass]="'header-' + (nivel + 1) + ' mb-4 mt-4 bold no-cursiva'"
                  [innerHTML]="removeCampoSuffix(key)"
                >
                </div>
              </div>

              <ion-grid>
                <ion-row>
                  <ion-col
                    *ngFor="let label of labels; let label_number = index"
                    size-xs="12"
                    size-sm="12"
                    size-md="6"
                  >
                    <div>
                      <label class="text-900 font-medium mb-2 mr-2"
                        >{{ label }}
                      </label>
                      <app-input-excel
                        [type]="deleteSuffix(data[key].tipo)"
                        [label]="label"
                        [(value)]="data[key][label]"
                        [required]="true"
                      ></app-input-excel>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            }
          }
        </div>
      </div>

      <div *ngIf="!data[key].tipo" class="mt-4">
        <span [ngClass]="'header-' + nivel">{{ key }}</span>
        <ng-container
          *ngTemplateOutlet="
            generateForm;
            context: { $implicit: data[key], nivel: nivel + 1 }
          "
        ></ng-container>
      </div>
    </div>
    <!--  -->
  </ng-template>

  <!-- Marcar unidad del campo de reporte XBRL -->
   @if(preview){
    <ion-grid class="mb-5">
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">
            Indique la unidad del campo de reporte XBRL  
          </label>
          <div class="mt-1">
            <p-dropdown
              [(ngModel)]="selectedUnit"
              appendTo="body"
              [options]="unitsData"
              optionLabel="name"
              optionValue="id"
              class="w-full"
              [style]="{'width':'100%'}"
              placeholder="Seleccione una unidad"
            >
            </p-dropdown>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  }

  <div class="submain-container">
    <!-- Se ha definido valor por defecto para campo con $ -->
    @if(preview && defaultField){
      <ion-grid>
        <ion-row>
          <ng-container *ngFor="let item of defaultFieldValue; let i = index; trackBy: trackByIndex">
            <ion-col 
              size-xs="12" 
              size-sm="12" 
              [attr.size-md]="defaultFieldValue.length > 1 ? '6' : '12'"
            >
              <label>Introduzca el valor para el campo por defecto</label>
              <div class="flex align-items-center">
                <p-inputNumber
                  [(ngModel)]="defaultFieldValue[i]"
                  placeholder="Introduce un valor numérico"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  [maxFractionDigits]="2"
                  class="w-full"
                ></p-inputNumber>
                @if(defaultFieldValue.length > 1){
                  <p-button
                    severity="danger"
                    icon="pi pi-times"
                    [loading]="loadingType" 
                    class="p-button-danger p-button-text ml-2"
                    (click)="removeRow(i)"
                  ></p-button>
                }
              </div>
            </ion-col>
          </ng-container>
          <div class="m-2 flex w-full align-items-center justify-content-center">
            <p-button
              severity="info"
              label="Agregar fila"
              icon="pi pi-plus"
              [loading]="loadingType" 
              (click)="addNewRow()"
            ></p-button>
          </div>
        </ion-row>
      </ion-grid>
    }

    <!-- No se ha definido valor por defecto para campo con $ -->
    @if(preview && !defaultField) {
      <div class="w-full text-center flex justify-content-center align-items-center mb-2">
        <label>
          El campo por defecto no ha sido definido con el carácter <strong>$</strong> o su tipo de dato no es numérico. Si el campo aperece reflejado en el formulario pero no aparece la entrada de datos aquí, verifique que su tipo de dato esté definido como <strong>number</strong> en el archivo Excel.
        </label>
      </div>
    }
  </div>

  @if(!completingForm){
    <p-button
      #submitButton
      [loading]="loadingForm"
      label="Confirmar vista previa"
      class="p-button-primary btn-submit pt-3"
      (click)="sendData()"
    ></p-button>
  }
</div>
