import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { marked } from 'marked';
import { ServerService } from 'src/app/services/server.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-chat-ai-new-window',
  templateUrl: './chat-ai-new-window.component.html',
  styleUrls: ['./chat-ai-new-window.component.scss'],
})
export class ChatAiNewWindowComponent  implements OnInit {
  userMessage: string = ''; // Mensaje del usuario
  messages: { text: any; sender: 'user' | 'bot', class : 'chat-messages__user' | 'chat-messages__bot' }[] = []; // Mensajes del chat
  isModalOpen = true;
  showNewWindow = false;
  suggestions: string[];
  currentSuggestion: number[] = [];
  suggestionError = false;
  isLoading: boolean = false;
  isExpanded: boolean = false; // Estado para saber si el modal está ampliado

  private scrollToBottom(): void {
    setTimeout(() => {
      const container = document.querySelector('.chat-messages');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, 0);
  }
  
  ngOnInit(){
    this.getSuggestions();
  }

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private location: Location
  ) {}

  dismiss() {
    this.isModalOpen = false;
  }

  toggleModalSize() {
    this.isExpanded = !this.isExpanded; // Alterna el estado entre ampliado y normal
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getSuggestions(){
    //enviar datos a la API de IA
    this.serverService.sendData('/api/chat-ia/suggestions').subscribe({
      next: (response) => {
        this.suggestions = response.sugerencias;
        this.scrollToBottom();
      },
      error: (error) => {
        console.error('Error en la API', error);
      },
    });

  }

  setSuggestion(index: number){
    if (this.currentSuggestion.includes(index)) {
      this.currentSuggestion.splice(this.currentSuggestion.indexOf(index), 1);
    } else {
      this.currentSuggestion.push(index);
    }
    this.suggestionError = false;
  }

  sendMessage() {
    if (this.currentSuggestion.length === 0) {
      this.suggestionError = true; // Resalta los botones en rojo
      console.warn('No hay sugerencia seleccionada');
      return;
    }

    this.isLoading = true;

    // Si hay una sugerencia seleccionada, procesamos el mensaje
    this.suggestionError = false;
    if (!this.userMessage.trim()) return;

    // Añadir el mensaje del usuario
    this.messages.push({ text: this.userMessage, sender: 'user', class: 'chat-messages__user'});
    this.scrollToBottom();

    const messageToSend = this.userMessage; // Guardar el mensaje para enviar
    const indexToSend = this.currentSuggestion;
    this.userMessage = ''; // Limpiar el textarea
 
    //enviar datos a la API de IA
    this.serverService.sendData('/api/chat-ia/chat',
      {
        message: messageToSend, 
        index: indexToSend          
      }
    ).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.simulateTypingEffect(marked.parse(response.message.content));
        this.scrollToBottom();
      },
      error: (error) => {
        console.error('Error en la API', error);
        this.isLoading = false;
      },
    });

  }

  private simulateTypingEffect(message: any) {
    const botMessage:any = { text: '', sender: 'bot', class: 'chat-messages__bot' };
    this.messages.push(botMessage); 

    let index = 0;       
    let delay = 50;     

    const typeCharacter = () => {
      if (index < message.length) {
        botMessage.text += message[index];
        index++;
        delay = Math.max(10, delay - 1);
        if (index === 500) {
          botMessage.text = message; 
          this.scrollToBottom();
          return;
        }
        setTimeout(typeCharacter, delay);
        this.scrollToBottom();
      }
    };

    typeCharacter();
  }

  openModal(){
    this.isModalOpen = true;
    this.getSuggestions();
  }

  openDialogInNewWindow(){
    const urlCompleta = this.location.prepareExternalUrl('/chat-ai-new-window');

    const nuevaVentana = window.open(
      urlCompleta, // URL del componente
      '_blank', // Nombre de la ventana
      'width=800,height=600,scrollbars=yes,resizable=yes' // Características de la ventana
    );
    this.isModalOpen = false;
  }
}
