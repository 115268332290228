import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationEvent,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './app.menu.service';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { TreeNode } from 'primeng/api';
import { AccordionTab } from 'primeng/accordion';
import { IonAccordionGroup } from '@ionic/angular';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-menuitem]',
  template: `
    @if(isDesktop){
      <ng-container>
        <div *ngIf="root" class="layout-menuitem-root-text">
          {{ item?.label }}
        </div>
        <a
          *ngIf="item?.items"
          (click)="itemClick($event)"
          (mouseenter)="onMouseEnter()"
          [ngClass]="item?.class"
          tabindex="0"
          pRipple
        >
          <i [ngClass]="item?.icon" class="layout-menuitem-icon"></i>
        </a>
        <a
          *ngIf="!item?.items"
          (click)="itemClick($event)"
          [ngClass]="item?.class"
          tabindex="0"
          pRipple
        >
          <i [ngClass]="item?.icon" class="layout-menuitem-icon"></i>
        </a>

        <!-- Sidebar -->
        @if(model.length > 0){
        <p-sidebar
          [(visible)]="sidebarVisible"
          [dismissible]="true"
          (onHide)="collapseAll()"
          styleClass="w-30rem"
        >
          <ion-accordion-group class="sidebar-menu-container">
            <ng-container *ngFor="let parent of model">
              <ng-container
                *ngIf="parent?.items?.length > 0; else noChildrenParent"
              >
                <!-- Nivel Padre con hijos -->
                <ion-accordion [value]="parent.label">
                  <ion-item lines="none" slot="header">
                    <i [class]="parent.icon" style="margin-right: 5px;"></i>
                    <ion-label>{{ parent?.label }}</ion-label>
                  </ion-item>
                  <div class="ion-padding" slot="content">
                    <!-- Nivel Hijo -->
                    <ion-accordion-group>
                      <ng-container *ngFor="let child of parent?.items">
                        <ng-container
                          *ngIf="
                            child?.items?.length > 0;
                            else childWithoutGrandchildren
                          "
                        >
                          <ion-accordion [value]="child.label">
                            <ion-item lines="none" slot="header">
                              <i
                                [class]="child?.icon"
                                style="margin-right: 5px;"
                              ></i>
                              <ion-label>{{ child?.label }}</ion-label>
                            </ion-item>
                            <div class="ion-padding" slot="content">
                              <!-- Nivel Nieto -->
                              <ng-container *ngFor="let item of child?.items">
                                <p
                                  *ngIf="item?.routerLink; else noRouterLink"
                                  (click)="navigateToRouterLink(item?.routerLink)"
                                  style="cursor: pointer; margin-left: 2vw;"
                                >
                                  {{ item?.label }}
                                </p>
                                <ng-template #noRouterLink>
                                  <p
                                    (click)="navigateToBasePage(item.id)"
                                    style="cursor: pointer; margin-left: 2vw;"
                                  >
                                    {{ item?.label }}
                                  </p>
                                </ng-template>
                              </ng-container>
                            </div>
                          </ion-accordion>
                        </ng-container>
                        <!-- Hijo sin Nietos -->
                        <ng-template #childWithoutGrandchildren>
                          <ion-item
                            *ngIf="child?.routerLink; else noRouterLinkChild"
                            lines="none"
                            (click)="navigateToRouterLink(child?.routerLink)"
                            style="cursor: pointer"
                          >
                            <i
                              [class]="child.icon"
                              style="margin-right: 5px;"
                            ></i>
                            <ion-label>{{ child?.label }}</ion-label>
                          </ion-item>
                          <ng-template #noRouterLinkChild>
                            <p
                              (click)="navigateToBasePage(child?.id)"
                              style="cursor: pointer; margin-left: 2vw;"
                            >
                              {{ child?.label }}
                            </p>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </ion-accordion-group>
                  </div>
                </ion-accordion>
              </ng-container>
              <!-- Nivel Padre sin hijos -->
              <ng-template #noChildrenParent>
                <ion-item
                  *ngIf="parent?.routerLink; else noRouterLinkParent"
                  lines="none"
                  (click)="navigateToRouterLink(parent?.routerLink)"
                  style="cursor: pointer"
                >
                  <i [class]="parent?.icon" style="margin-right: 5px;"></i>
                  <ion-label>{{ parent?.label }}</ion-label>
                </ion-item>
                <ng-template #noRouterLinkParent>
                  <ion-item
                    (click)="navigateToBasePage(parent?.id)"
                    lines="none"
                    style="cursor: pointer"
                  >
                    <i [class]="parent?.icon" style="margin-right: 5px;"></i>
                    <ion-label>{{ parent?.label }}</ion-label>
                  </ion-item>
                </ng-template>
              </ng-template>
            </ng-container>
          </ion-accordion-group>
        </p-sidebar>
        }
      </ng-container>
    } @else {
      <ng-container>
        <div *ngIf="root" class="layout-menuitem-root-text">
          {{ item?.label }}
        </div>
        <a
          *ngIf="item?.items"
          [attr.href]="item?.url"
          (click)="itemClick($event)"
          (mouseenter)="onMouseEnter()"
          [ngClass]="item?.class"
          [attr.target]="item?.target"
          tabindex="0"
          pRipple
          [pTooltip]="item?.label"
          [tooltipDisabled]="!(isSlim && root && !active)"
        >
          <i [ngClass]="item?.icon" class="layout-menuitem-icon"></i>
          @if((item?.main_menu_hierarchy_levels_id && item?.main_menu_hierarchy_levels_id > 1) || !item.hasOwnProperty('id')){
            <span class="layout-menuitem-text">{{ item?.label }}</span>
          }
          <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            *ngIf="item?.items"
          ></i>
        </a>
        <a
          *ngIf="item && !item.items"
          (click)="itemClick($event)"
          (mouseenter)="onMouseEnter()"
          [ngClass]="item?.class"
          routerLinkActive="active-route"
          [routerLinkActiveOptions]="
            item.routerLinkActiveOptions || {
              paths: 'exact',
              queryParams: 'ignored',
              matrixParams: 'ignored',
              fragment: 'ignored'
            }
          "
          [fragment]="item.fragment"
          [queryParamsHandling]="item.queryParamsHandling"
          [preserveFragment]="item.preserveFragment!"
          [skipLocationChange]="item.skipLocationChange!"
          [replaceUrl]="item?.replaceUrl!"
          [state]="item.state"
          [queryParams]="item.queryParams"
          [attr.target]="item?.target"
          tabindex="0"
          pRipple
          [pTooltip]="item?.label"
          [tooltipDisabled]="!(isSlim && root)"
        >
          <i [ngClass]="item?.icon" class="layout-menuitem-icon"></i>
          @if((item?.main_menu_hierarchy_levels_id && item?.main_menu_hierarchy_levels_id > 1) || !item.hasOwnProperty('id')){
            <span class="layout-menuitem-text">{{ item?.label }}</span>
          }
          <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            *ngIf="item?.items"
          ></i>
        </a>

        <ul
          #submenu
          *ngIf="item && item?.items"
          [@children]="submenuAnimation"
          (@children.done)="onSubmenuAnimated($event)"
        >
          <ng-template ngFor let-child let-i="index" [ngForOf]="item?.items">
            <li
              app-menuitem
              [item]="child"
              [index]="i"
              [parentKey]="key"
              [class]="child.badgeClass"
            ></li>
          </ng-template>
        </ul>
      </ng-container>
    }
  `,
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
        })
      ),
      state(
        'hidden',
        style({
          display: 'none',
        })
      ),
      state(
        'visible',
        style({
          display: 'block',
        })
      ),
      transition(
        'collapsed <=> expanded',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() selectedItem: any;
  @Input() sidebarVisible: boolean = false;
  @Input() model: any[];
  @Input() index!: number;
  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;
  @Input() parentKey!: string;
  @ViewChild('submenu') submenu!: ElementRef;
  @ViewChildren(AccordionTab) accordionTabs!: QueryList<AccordionTab>;
  @ViewChild('accordion') accordion!: IonAccordionGroup;

  active = false;
  menuSourceSubscription: Subscription;
  menuResetSubscription: Subscription;
  key: string = '';

  constructor(
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    private appSidebar: AppSidebarComponent,
    public router: Router,
    private menuService: MenuService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (value) => {
        Promise.resolve(null).then(() => {
          if (value.routeEvent) {
            this.active =
              value.key === this.key || value.key.startsWith(this.key + '-')
                ? true
                : false;
          } else {
            if (
              value.key !== this.key &&
              !value.key.startsWith(this.key + '-')
            ) {
              this.active = false;
            }
          }
        });
      }
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((params) => {
        if (this.isSlimPlus || this.isSlim) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          }
        }
      });
  }

  ngOnInit() {
    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);

    if (!this.isSlim && this.item?.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  ngAfterViewChecked() {
    if (
      this.root &&
      this.active &&
      this.layoutService.isDesktop() &&
      (this.layoutService.isSlim() || this.layoutService.isSlimPlus())
    ) {
      this.calculatePosition(
        this.submenu?.nativeElement,
        this.submenu?.nativeElement.parentElement
      );
    }
  }

  onSubmenuAnimated(event: AnimationEvent) {
    if (
      event.toState === 'visible' &&
      this.layoutService.isDesktop() &&
      (this.layoutService.isSlim() || this.layoutService.isSlimPlus())
    ) {
      const el = <HTMLUListElement>event.element;
      const elParent = <HTMLUListElement>el.parentElement;
      this.calculatePosition(el, elParent);
    }
  }

  private closeAllAccordionTabs() {
    if (this.accordionTabs) {
      this.accordionTabs.forEach((tab) => {
        tab.selected = false; // Colapsar el tab
      });
    }

    // Restablecer el estado de los elementos anidados
    this.resetMenuState(this.model);
  }

  private resetMenuState(menu: any[]) {
    menu.forEach((item) => {
      item.expanded = false; // Asegúrate de que no se mantengan desplegados
      if (item.items && item.items.length > 0) {
        this.resetMenuState(item.items); // Recursivamente restablece el estado de los niveles anidados
      }
    });
  }

  onMenuItemClick(item: any): void {
    if (!item.items?.length) {
      // Si no tiene hijos, navega
      if (item.routerLink) {
        this.navigateToRouterLink(item.routerLink);
      } else if (item.id) {
        this.navigateToBasePage(item.id);
      }
    }
  }

  navigateToBasePage(id: number): void {
    this.sidebarVisible = false;
    if (id) {
      this.router.navigate([`/base-page/${id}`]);
    }
  }

  navigateToRouterLink(routerLink: string[]): void {
    if (routerLink) {
      this.sidebarVisible = false;
      this.router.navigate(routerLink);
    }
  }

  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }

  collapseAll(): void {
    const accordionGroups = document.querySelectorAll('ion-accordion-group');
    accordionGroups.forEach((group) => {
      group.value = null; // Colapsa todos los acordeones
    });
  }

  calculatePosition(overlay: HTMLElement, target: HTMLElement) {
    if (overlay) {
      const { left, top } = target.getBoundingClientRect();
      const vHeight = window.innerHeight;
      const oHeight = overlay.offsetHeight;
      const topbarEl = document.querySelector('.layout-topbar') as HTMLElement;
      const topbarHeight = topbarEl?.offsetHeight || 0;
      // reset
      overlay.style.top = '';
      overlay.style.left = '';

      if (this.layoutService.isSlim() || this.layoutService.isSlimPlus()) {
        const topOffset = top - topbarHeight;
        const height = topOffset + oHeight + topbarHeight;
        overlay.style.top =
          vHeight < height
            ? `${topOffset - (height - vHeight)}px`
            : `${topOffset}px`;
      }
    }
  }

  updateActiveStateFromRoute() {
    // let activeRoute = this.router.isActive(
    //   this.item.routerLink[0],
    //   <IsActiveMatchOptions>this.item.routerLinkActiveOptions || {
    //     paths: 'exact',
    //     queryParams: 'ignored',
    //     matrixParams: 'ignored',
    //     fragment: 'ignored',
    //   }
    // );
    // if (activeRoute) {
    //   this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    // }
  }

  itemClick(event: MouseEvent) {
    if (this.isDesktop) {
      this.selectedItem = this.item;
      this.sidebarVisible = true;
    } else {
      // avoid processing disabled items
      if (this.item.disabled) {
        event.preventDefault();
        return;
      }

      // Si estamos en el último nivel, entramos a base-page con el id del nivel nieto que toque
      if (
        this.item.main_menu_hierarchy_levels_id === 3 &&
        !this.item.added &&
        this.item.parent_id
      ) {
        this.router.navigate([`/base-page/${this.item.id}`]);
      } else if (this.item.added) {
        this.router.navigate(this.item.routerLink);
      }

      // add tab
      if (
        event.metaKey &&
        this.item.routerLink &&
        (!this.item.data || !this.item.data.fullPage)
      ) {
        this.layoutService.onTabOpen(this.item);
        event.preventDefault();
      }

      // toggle active state
      if (this.item.items) {
        this.active = !this.active;
        if (this.root && this.active && (this.isSlim || this.isSlimPlus)) {
          this.layoutService.onOverlaySubmenuOpen();
        }
      } else {
        if (this.layoutService.isMobile()) {
          this.layoutService.state.staticMenuMobileActive = false;
        }

        if (this.isSlim || this.isSlimPlus) {
          this.menuService.reset();
          this.layoutService.state.menuHoverActive = false;
        }
      }

      this.menuService.onMenuStateChange({ key: this.key });
    }
  }

  transformModelToTree(model: any[]): TreeNode[] {
    const transformNode = (node: any): TreeNode => ({
      key: node.id?.toString(),
      label: node.label,
      data: node.items ? null : { id: node.id }, // La `data` solo se agrega a los nodos de nivel nieto
      children: node.items ? node.items.map(transformNode) : null,
    });

    return model.map(transformNode);
  }

  onMouseEnter() {
    // activate item on hover
    if (
      this.root &&
      (this.isSlim || this.isSlimPlus) &&
      this.layoutService.isDesktop()
    ) {
      // if (this.layoutService.state.menuHoverActive) {
      //   this.active = true;
      //   this.menuService.onMenuStateChange({ key: this.key });
      // }
    }
  }

  get submenuAnimation() {
    if (
      this.layoutService.isDesktop() &&
      (this.layoutService.isSlim() || this.layoutService.isSlimPlus())
    )
      return this.active ? 'visible' : 'hidden';
    else return this.root ? 'expanded' : this.active ? 'expanded' : 'collapsed';
  }

  get isSlim() {
    return this.layoutService.isSlim();
  }

  get isSlimPlus() {
    return this.layoutService.isSlimPlus();
  }

  get isMobile() {
    return this.layoutService.isMobile();
  }

  get isDesktop() {
    return this.layoutService.isDesktop();
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root;
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
