import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { firstValueFrom } from 'rxjs';
import { ImpactRiskOpportunity } from 'src/app/models/impact-risk-opportunity';
import { Indicator } from 'src/app/models/indicator';
import { ServerService } from 'src/app/services/server.service';
import { numberScale, riskMatrixColors, YesOrNo } from 'src/app/utils';

@Component({
  selector: 'app-dual-materiality',
  templateUrl: './dual-materiality.page.html',
  styleUrls: ['./dual-materiality.page.scss'],
})
export class DualMaterialityPage implements OnInit {
  @ViewChild('op') overlayPanel!: OverlayPanel; // Referencia al Overlay Panel
  @ViewChild('fileUpload') fileUploadRef!: FileUpload;
  dialogBulkEdit: boolean = false;
  dialogFieldSelected = false;
  bulkToIndividualConfirmed: boolean = false;
  indicatorsByDmId: any[] = [];
  impactCounter: number = 0;
  isTabOpen: boolean = false;
  mockIndicator = [{}];
  dmName: string = '';
  dialogStep2Table: boolean = false;
  companyId: any;
  dmSelected: any | null = null;
  stakeholdersSelected: any[] = [];
  selectedReportGroup: any;
  loadingReport: boolean = false;
  showIndicatorsByDmLevelDialog: boolean = false;
  showIndicatorsByDmDetailDialog: boolean = false;
  editImpact: boolean = false;
  dialogBulkToIndividualUpdate: boolean = false;
  model: any[] = [];
  matFieldSelected: any;
  loading: boolean = true;
  totales: object = {};
  groupSelected: any;
  dialogDualMateriality: boolean = false;
  dmData: any[] = [];
  matrixGroupData: any;
  impactMatrixGroupData: any;
  groupsWithDmData: any[] = [];
  form1: FormGroup;
  form2: FormGroup;
  generalIndicator: boolean = false;
  form5: FormGroup;
  formStakeholders: FormGroup;
  form6: FormGroup;
  updateFieldDocument: File | null = null;
  formUpdateField: FormGroup;
  formImpact: FormGroup;
  active: any = 0;
  loadingModal: boolean = false;
  formDMGroup: FormGroup;
  dialogDualMaterialityForm: boolean = false;
  dialogGroupForm: boolean = false;
  selectedDm: any | null;
  edit: boolean = false;
  dmGroups: any[] = [];
  selectedIndicator: any;
  dialogImpactRisk: boolean = false;
  dialogForm: boolean = false;
  dialogGroup: boolean = false;
  selectedGroup: any | null = null;
  selectedTableGroup: any;
  uploadedFiles: { [step: string]: { [section: string]: File } } = {};
  loadingForm: boolean = false;
  numberScale = numberScale;
  YesOrNo = YesOrNo;
  impacts = [5, 4, 3, 2, 1];
  financialRelevances = [1, 2, 3, 4, 5];
  dialogConfirmationReportDocument: boolean = false;
  dialogDeleteDm: boolean = false;
  dialogDeleteGroup: boolean = false;
  dialogPreviewSubmit: boolean = false;
  loadingModalBtn: boolean = false;
  dialogMatrixClicked: boolean = false;
  indicatorsByDmGroupClicked: any | null = null;
  showMaterialImpactsDialog: boolean = false;
  reorderedImpacts: any[] = [];
  yesNoOptions = [
    { label: 'Sí', value: 1 },
    { label: 'No', value: 0 },
  ];
  riskMatrixColors = riskMatrixColors;
  riskRecords: any[] = [];
  stepperIndex: number = 0;
  impactRisks = [5, 4, 3, 2, 1];
  influenceLevels = [1, 2, 3, 4, 5];
  existingFiles: {
    [step: number]: { [section: number]: { url: string; name: string } };
  } = {};
  keyMapping: { [mainKey: number]: { [subKey: number]: string } } = {
    1: { // Paso 1
      1: 'regulations_document',
      2: 'business_document',
      3: 'understanding_stakeholders_document',
    },
    2: { // Paso 2
      1: 'internal_consultations_document',
      2: 'external_consultations_document',
    },
    3: { // Paso 5
      1: 'impact_document',
      2: 'financial_relevance_document',
    },
  };
  indicators: Indicator[] = [];
  selectedIndicators: any[] = [];
  dialogRiskMatrix: boolean = false;
  impactRiskOportunity: ImpactRiskOpportunity[] = [];
  materialImpacts: ImpactRiskOpportunity[] = [];

  upstreamDownstream: any[] = [];
  selectedItemStep3: any;
  selectedFieldStep3: string = '';
  selectedIndexStep3: number = -1;

  financialMateriality: any[] = [];
  selectedItemStep4: any;
  selectedFieldStep4: string = '';
  selectedIndexStep4: number = -1;

  dynamicFormArrayStep7: any[] = [];
  label_field: string = '';
  selectedImpactRegister: ImpactRiskOpportunity | null;

  // Documentos para formulario de impacto
  uploadedFilesImpact: File | null = null;
  uploadedFilesRisk: File | null = null;
  uploadedFilesOpportunity: File | null = null;

  crudOptionsCompanies: any [] = [
    {
      label: 'Eliminar',
      value: 1,
    },
    {
      label: 'Editar',
      value: 2,
    },
    {
      label: 'Edición masiva',
      value: 3,
    },
  ];

  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getIndicators();

    this.form1 = this.fb.group({
      regulations_text: [''],
      business_text: [''],
    });

    this.form2 = this.fb.group({
      internal_consultations_text: [''],
      external_consultations_text: [''],
    });

    this.form5 = this.fb.group({
      impact: [''],
      impact_text: [''],
      financial_relevance: [''],
      financial_relevance_text: [''],
    });

    this.formDMGroup = this.fb.group({
      name: ['', Validators.required],
    });

    this.formImpact = this.fb.group({
      impact_form_impact_text: ['', Validators.required],
      impact_form_risk_text: ['', Validators.required],
      impact_form_opportunity_text: ['', Validators.required],
      indicators_selected: ['']
    });

    this.formUpdateField = this.fb.group({
      label_field: ['', Validators.required],
      value: [''],
      document: [''],
    });

    this.formStakeholders = this.fb.group({
      stakeholders: this.fb.array([]) // Inicializa con un formulario
    });

    this.companyId = localStorage.getItem('companyId');
    this.getDualMaterialitiesByGroup();
  }

  /**
   * Inicialización de la parte del formulario de partes interesadas
   */
  createStakeholder(): FormGroup {
    return this.fb.group({
      id: [''],
      name: [''],
      influence_level: [''],
      impact_risks: [''],
      description: [''],
      total: [''],
      priority: ['']
    });
  }

  /**
   * Inicialización con datos de response para formulario de partes interesadas
   */
  createStakeholderForm(data: any): FormGroup {
    return this.fb.group({
      id: [data.id || ''],
      name: [data.name || ''],
      influence_level: [Number(data.influence_level) || null],
      impact_risks: [Number(data.impact_risks) || null],
      total: [Number(data.total) || null], // Calcula el total
      description: [data.description || ''],
      priority: [data.priority || null]
    });
  }

  /**
   * Manejo de captura de escritura de nombre de registro para actualizar matriz
   */
  updateMatrixByNameInput(event){
    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;

    // Actualizar matriz
    this.getStep2MatrixData(stakeholdersArray.value);
  }

  /**
   * Manejo de acceso a formulario de stakeholders
   */
  get stakeholdersArray(): FormArray {
    return this.formStakeholders.get('stakeholders') as FormArray;
  }

  /**
   * Eliminar localmente registro de formulario de paso 2
   * @param index 
   */
  removeStakeholder(event, index: number, item): void {
    // Si estamos creando registro o estamos editando y queremos borrar un registro local
    if((this.edit && item.id === "") || !this.edit){
      const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
      stakeholdersArray.removeAt(index);

      // Actualizar matriz
      this.getStep2MatrixData(stakeholdersArray.value);
    }

    // Si estamos editando y tenemos registro en la base de datos
    if(this.edit && item.id !== ""){
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        icon: 'pi pi-exclamation-triangle',
        header: 'Confirmar eliminación',
        message: '¿Está seguro de que desea eliminar este registro?',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        accept: () => {
          this.loadingForm = true;

          this.serverService.deleteData(`/api/dual-materiality/stakeholder/${item.id}`).subscribe({
            next: (response) => {
              if(response.data){
                this.messageService.add({
                  severity:'success',
                  summary: 'Registro eliminado',
                  detail: 'El registro ha sido eliminado correctamente.',
                });

                this.loadingForm = false;
                const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
                stakeholdersArray.removeAt(index);

                // Actualizar matriz
                this.getStep2MatrixData(stakeholdersArray.value);
              }
            },
            error: (error) => {
              this.loadingForm = false;
              console.error(error);
              this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail: 'Ocurrió un error al eliminar el registro, inténtelo de nuevo',
              });
            }
          });
        },
        reject: () => {}
      });
    }
  }

  /**
   * Añadir nuevo registro en formulario de paso 2
   */
  addStakeholder(): void {
    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;

    if(stakeholdersArray.value.length === 0 || this.isCurrentStakeholderComplete()){
      stakeholdersArray.push(this.createStakeholder());
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos obligatorios antes de crear un nuevo registro',
      });
    }

  }

  /**
   * Validador de registro cumplimentado para formulario de paso 2
   */
  isCurrentStakeholderComplete(): boolean {
    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
    if (stakeholdersArray.length === 0) return false;
  
    const lastStakeholder = stakeholdersArray.at(stakeholdersArray.length - 1);
    const values = lastStakeholder.value;
  
    // Excluir los campos 'description' e 'id' de la validación
    return Object.keys(values).every(
      key => key === 'description' || key === 'id' || (values[key] !== null && values[key] !== '')
    );
  }

  /**
   * Enviar registro de paso 2 de formulario modificado
   */
  async onSubmitUpdateStakeholderRegister(index){
    this.loadingForm = true;
    this.updateTotalByItem(index);

    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
    const stakeholder = stakeholdersArray.at(index) as FormGroup;

    this.serverService.updateData(`/api/dual-materiality/stakeholder/${stakeholder.value.id}`, stakeholder.value).subscribe({
      next: (response) => {
        if(response.data){
          this.messageService.add({
            severity:'success',
            summary: 'Registro modificado',
            detail: 'El registro ha sido modificado correctamente.',
          });
        }
        this.loadingForm = false;
      },
      error: (error) => {
        this.loadingForm = false;
        console.error(error);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Ocurrió un error al intentar modificar el registro, inténtelo de nuevo',
        });
      }
    })
  }

  /**
   * Actualizar valor total de multiplación entre nivel e impacto
   * @param index
   */
  updateTotal(index: number){
    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
    const stakeholder = stakeholdersArray.at(index) as FormGroup;
  
    const impact = stakeholder.get('impact_risks')?.value || 0;
    const level = stakeholder.get('influence_level')?.value || 0;
  
    // Actualizar campo "total"
    stakeholder.get('total')?.setValue(impact * level);

    // Actualizar campo "priority"
    const total = stakeholder.get('total')?.value || 0;

    if(total >= 16){
      stakeholder.get('priority')?.setValue('Alta');
    } else if (total >= 9){
      stakeholder.get('priority')?.setValue('Media');
    } else {
      stakeholder.get('priority')?.setValue('Baja');
    }

    // Actualizar matriz
    this.getStep2MatrixData(stakeholdersArray.value);
  }

  /**
   * Actualizar valor total de multiplación entre nivel e impacto de un registro
   * @param index
   */
  updateTotalByItem(index){
    const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
    const stakeholder = stakeholdersArray.at(index) as FormGroup;

    const total = stakeholder.get('total')?.value || 0;

    if(total >= 16){
      stakeholder.get('priority')?.setValue('Alta');
    } else if (total >= 9){
      stakeholder.get('priority')?.setValue('Media');
    } else {
      stakeholder.get('priority')?.setValue('Baja');
    }

    // Actualizar matriz
    this.getStep2MatrixData(stakeholdersArray.value);
  }

  /**
   * Control de estilo de celdas de tabla
   * @param value
   * @returns
   */
  getColorClass(value: number): string {
    switch (value) {
      case 1:
        return 'low';
      case 2:
        return 'medium-low';
      case 3:
        return 'medium';
      case 4:
        return 'medium-high';
      case 5:
        return 'high';
      default:
        return '';
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 3
   * @param event
   * @param overlayPanel
   */
  openOverlayStep3(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep3 = field;
    this.selectedItemStep3 = item.upstreamDownstream; // Referencia anidada
    this.selectedIndexStep3 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Usamos la referencia del ViewChild
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 4
   * @param event
   * @param overlayPanel
   */
  openOverlayStep4(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep4 = field;
    this.selectedItemStep4 = item.financialMateriality; // Referencia anidada
    this.selectedIndexStep4 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event);
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Actualizar localmente datos de la tabla paso 3
   * @param overlayPanel
   * @param index
   * @param field
   * @param value
   */
  updateValueStep3(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    const currentSelectedItem = this.impactRiskOportunity[index].upstreamDownstream;
    this.impactRiskOportunity[index].upstreamDownstream[field] = value;

    // Si estamos editando tabla y el registro tiene id, lo actualizamos en el momento
    if(this.edit && currentSelectedItem.hasOwnProperty('id')){
      this.updateMatrixFieldValue(3, index);
    }

    this.getImpactRiskMatrixData(this.impactRiskOportunity);
    this.cdr.detectChanges();
    overlayPanel.hide();
  }

  /**
   * Actualizar localmente datos de la tabla paso 4
   * @param overlayPanel
   * @param index
   * @param field
   * @param value
   */
  updateValueStep4(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    const currentSelectedItem = this.impactRiskOportunity[index].financialMateriality;
    this.impactRiskOportunity[index].financialMateriality[field] = value;

    if(this.edit && currentSelectedItem.hasOwnProperty('id')){
      this.updateMatrixFieldValue(4, index);
    }

    this.getImpactRiskMatrixData(this.impactRiskOportunity);
    this.cdr.detectChanges();
    overlayPanel.hide();
  }

  /**
   * Actualización y guardado de celda de tablas de paso 6 o 7
   * @param step 
   * @param index 
   */
  updateMatrixFieldValue(step: number, index){
    const itemToUpdate = this.impactRiskOportunity[index];

    const url = step === 3 ? `/api/dual-materiality/impact/updown/${itemToUpdate['upstreamDownstream'].id}` : 
      `/api/dual-materiality/impact/lca/${itemToUpdate['financialMateriality'].id}`
    const data = step === 3 ? itemToUpdate['upstreamDownstream'] : itemToUpdate['financialMateriality'];

    this.serverService.updateData(url, data).subscribe({
      next: (response) => {
        if(response.data){
          return this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Valor actualizado correctamente',
          });
        }
      },
      error: (err) => {
        console.error('Error al actualizar', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Error al actualizar el valor',
        });
      }
    })
  }

  /**
   * Obtener listado de indicadores para listarlos al crear un impacto
   */
  getIndicators() {
    this.serverService
      .getData('/api/main-menus/getParentWithIndicators/')
      .subscribe({
        next: (response) => {
          this.indicators = response.data.reduce((acc, item) => {
            const indicatorsWithParent = item.indicators.map((indicator) => ({
              ...indicator,
              parent: item.name,
            }));
            return acc.concat(indicatorsWithParent);
          }, []);
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
        },
      });
  }

  /**
   * Obtener registros de doble materialidad por grupos (estos datos se pintarán en la matriz de riesgos)
   */
  getDualMaterialitiesByGroup() {
    this.serverService.getData(`/api/dual-materiality/groups`).subscribe({
      next: (response) => {
        this.groupsWithDmData = response.data ? response.data : [];
        this.dmGroups = response.data ? response.data : [];
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        console.error(
          'Error al obtener los registros de doble materialidad por grupos',
          err
        );
      },
    });
  }

  /**
   * Abrir modal de matriz de riesgos
   */
  async openRiskMatrixDialog(group: any) {
    // Si no hay datos para el grupo seleccionado
    await this.getRiskMatrixData(group);

    if(Object.keys(this.matrixGroupData.matrixData).length === 0){
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'No hay datos para mostrar en la matriz de riesgos',
      })
    }
    this.dialogRiskMatrix = true;
  }

  /**
   * Definir estilos en matriz de riesgos
   * @param rowIndex
   * @param colIndex
   * @returns
   */
  getCellClass(rowIndex: number, colIndex: number): string {
    return this.riskMatrixColors[rowIndex][colIndex];
  }

  /**
   * Función que filtra los registros para devolver solo aquellos que coinciden con la frecuencia e impacto dados e imprimirlos en la celda actual
   * @param frequency
   * @param impact
   * @returns
   */
  getRecordsForCell(frequency: string, impact: string): any[] {
    return this.riskRecords.filter(
      (record) => record.frequency === frequency && record.impact === impact
    );
  }

  /**
   * Manejar pulsación de botón para nuevo grupo o doble materialidad para multiples indicadores
   * @param event
   */
  handleAddButton(event) {
    this.dialogGroup = true;
    this.dialogForm = false;
  }

  /**
   * Manejador de selección de tipo de formulario
   */
  formSelected(type: number) {
    if (type === 1) {
    }

    if (type === 2) {
      this.dialogGroup = true;
      this.dialogForm = false;
    }
  }

  /**
   * Mostrar modal de creación/edición de grupo
   */
  showDialog(group?: any) {
    if (group) {
      this.selectedTableGroup = group;
      this.formDMGroup.patchValue(group);
    }

    this.edit = group ? true : false;
    this.dialogGroup = false;
    this.dialogGroupForm = true;
  }

  /**
   * Generador de datos en las celdas de matriz de riesgos para un grupo
   */
  async getRiskMatrixData(group: any): Promise<void> {  
    // Inicializamos la estructura de datos para la matriz
    this.matrixGroupData = {
      matrixData: {},
    };
  
    // Verificamos si existe dualMat y si es un array
    if (group.dualMat && Array.isArray(group.dualMat)) {
      // Iteramos sobre dualMat
      group.dualMat.forEach((dualMatEntry) => {
        const dualMat3 = dualMatEntry.dualMat3;
  
        if (dualMat3) {
          // Obtenemos los valores de impact y financial_relevance
          const impact = Number(dualMat3.impact);
          const financialRelevance = Number(dualMat3.financial_relevance);
  
          // Validamos que los valores estén en el rango esperado
          if (
            impact >= 1 &&
            impact <= 5 &&
            financialRelevance >= 1 &&
            financialRelevance <= 5
          ) {
            // Inicializamos la celda correspondiente si no existe
            if (!this.matrixGroupData.matrixData[impact]) {
              this.matrixGroupData.matrixData[impact] = {};
            }
            if (!this.matrixGroupData.matrixData[impact][financialRelevance]) {
              this.matrixGroupData.matrixData[impact][financialRelevance] = [];
            }
  
            // Agregamos el registro a la celda
            this.matrixGroupData.matrixData[impact][financialRelevance].push({
              ...dualMatEntry,
              id: dualMat3.id,
              groupName: group.name,
              label: `Doble materialidad nº${dualMat3.id}`,
            });
          }
        }
      });
    }
  }
  
  /**
   * Manejar modal para mostrar indicadores de un p-chip pulsado
   */
  showIndicatorsByChip(items) {
    this.dmSelected = items;
    this.showIndicatorsByDmDetailDialog = true;
  }

  showIndicatorsByChipByDmGroup(items) {
    this.dmSelected = items;
    this.showIndicatorsByDmLevelDialog = true;
  }

  /**
   * Manejador de cierre de modal de doble materialidad
   */
  handleHideDualMateriality() {
    // Reiniciar grupo e indicadores seleccionados
    this.selectedGroup = null;
    this.selectedIndicators = [];

    // Reiniciar formularios generales
    this.form1.reset();
    this.form2.reset();
    this.form5.reset();
    this.formStakeholders.reset();

    // Reiniciar formArray de registro de paso 2
    this.stakeholdersArray.clear();

    // Reiniciar array de impactos de un registro
    this.impactRiskOportunity = [];

    // Reiniciar campo paso 1
    this.dmName = "";
    
    // Reinicia el índice del stepper
    this.stepperIndex = 0;

    // Reiniciar array de las matrices de un registro
    this.impactMatrixGroupData = [];
    this.matrixGroupData = {};

    // Reiniciar paso 10
    this.dynamicFormArrayStep7 = [];
  }

  handleGeneralIndicador(event: any){
    this.selectedIndicators = [];
  }

  /**
   * Enviar formulario de doble materialidad
   */
  onSubmitForm() {
    const formData = new FormData();
    const allFormData = {
      ...this.form1.value,
      ...this.form2.value,
      ...this.form5.value,
    };

    // Si no define nombre al registro
    if(this.dmName === ''){
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe ingresar un nombre al registro en el paso 1',
      })
    }

    // Procesar datos del formulario excluyendo los vacíos
    Object.keys(allFormData).forEach((key) => {
      const value = allFormData[key];
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });

    // Procesar los documentos y mapear las claves
    Object.keys(this.uploadedFiles).forEach((mainKey) => {
      Object.keys(this.uploadedFiles[mainKey]).forEach((subKey) => {
        const file = this.uploadedFiles[mainKey][subKey];
        const key = this.keyMapping[Number(mainKey)]?.[Number(subKey)];
        if (key && file) {
          formData.append(key, file);
        }
      });
    });

    // Procesar impactos creados en el paso 2
    if(this.edit && !this.editImpact){ // Siempre hay impactos si editamos un dm
      const filteredImpactRiskOportunity = this.impactRiskOportunity.filter(item => !item.hasOwnProperty('id'));

      filteredImpactRiskOportunity.forEach((record, index) => {
        Object.keys(record).forEach((key) => {
          const value = record[key];

          // Si el valor es un objeto, agregar sus propiedades individualmente
          if (key === 'upstreamDownstream' || key === 'financialMateriality') {
            Object.keys(value).forEach((subKey) => {
              formData.append(
                `impactRiskOportunity[${index}][${key}][${subKey}]`,
                value[subKey]
              );
            });
          } else {
            // Agregar valores simples directamente
            formData.append(`impactRiskOportunity[${index}][${key}]`, value);
          }
        });
      });
    } else {
      if(this.impactRiskOportunity.length > 0) {
        this.impactRiskOportunity.forEach((record, index) => {
          Object.keys(record).forEach((key) => {
            const value = record[key];
  
            // Si el valor es un objeto, agregar sus propiedades individualmente
            if (key === 'upstreamDownstream' || key === 'financialMateriality') {
              Object.keys(value).forEach((subKey) => {
                formData.append(
                  `impactRiskOportunity[${index}][${key}][${subKey}]`,
                  value[subKey]
                );
              });
            } else {
              // Agregar valores simples directamente
              formData.append(`impactRiskOportunity[${index}][${key}]`, value);
            }
          });
        });
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe agregar al menos un impacto',
        })
      }
    }

    // Comprobar tablas cumplimentadas de paso 3 y 4
    let hasFinancialData, hasUpstreamData;
    this.impactRiskOportunity.forEach(item => {
      hasFinancialData = this.hasData(item.financialMateriality);
      hasUpstreamData = this.hasData(item.upstreamDownstream);
    });

    if(!hasFinancialData && !hasUpstreamData){
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe completar los datos de las tablas del paso 6 y 7',
      })
    }

    if(this.edit){ // Siempre hay impactos si editamos un dm
      const filteredMatFields = this.dynamicFormArrayStep7.filter(item => !item.hasOwnProperty('id'));

      // Procesar datos del paso 6 con los campos dinámicos
      filteredMatFields.forEach((record, index) => {
        Object.keys(record).forEach((key) => {
          if (key === 'file' && record[key]) {
            formData.append(`dualMatFields[${index}][file]`, record.file);
          } else {
            const value = record[key];
            formData.append(
              `dualMatFields[${index}][${key}]`,
              value !== null ? value : ''
            );
          }
        });
      });
    } else {
      // Procesar datos del paso 6 con los campos dinámicos
      this.dynamicFormArrayStep7.forEach((record, index) => {
        Object.keys(record).forEach((key) => {
          if (key === 'file' && record[key]) {
            formData.append(`dualMatFields[${index}][file]`, record.file);
          } else {
            const value = record[key];
            formData.append(
              `dualMatFields[${index}][${key}]`,
              value !== null ? value : ''
            );
          }
        });
      });
    }

    // Enviamos por post solo los nuevos registros
    if(this.edit){
      const filteredMatStakeholders = this.formStakeholders.value['stakeholders'].filter(
        item => item.id === ""
      );

      filteredMatStakeholders.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`stakeholders[${index}][${key}]`, item[key] || '');
        });
      });
    } else {
      const stakeholdersForm = this.formStakeholders.value;

      stakeholdersForm['stakeholders'].forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`stakeholders[${index}][${key}]`, item[key] || '');
        });
      });
    }

    formData.append('dual_mat_group_id', this.selectedGroup);
    formData.append('name', this.dmName);

    // Procesar datos al API
    this.loadingForm = true;

    // Distinguimos si estamos creando o editando (solo paso 1, 2 y 5)
    const request = this.edit
    ? this.serverService.updatePostData(
        `/api/dual-materialities/${this.selectedDm.id}`,
        formData
      )
    : this.serverService.sendData(
        `/api/dual-materialities`,
        formData
      );

    request.subscribe({
      next: (response) => {
        if (response.data) {
          this.refreshData();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Registro de doble materialidad guardado correctamente',
          });
        }
        this.loadingForm = false;
        this.dynamicFormArrayStep7 = [];
        this.impactRiskOportunity = [];
        this.dmName = '';
      },
      error: (err) => {
        console.error(
          'Error al guardar el registro de doble materialidad',
          err
        );
        const errorObject = err;
        if(errorObject?.error?.message.includes('unique')){
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ya existe un registro de doble materialidad con ese nombre. Por favor, elija un nombre diferente.',
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Error al guardar el registro de doble materialidad, inténtelo de nuevo',
          });
        }

        this.loadingForm = false;
      },
    });
  }

  /**
   * Función auxiliar para validar tablas de valores de impactos (TODO: REVISAR)
   */
  hasData(obj: any): boolean {
    return Object.entries(obj)
    .filter(([key]) => key !== 'material_impact') // Excluir material_impact
    .every(([, value]) => value !== null);
  }

  /**
   * Enviar formulario de grupo de doble materialidad
   */
  async onSubmitGroupForm() {
    this.formDMGroup.markAllAsTouched();

    if (this.formDMGroup.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise el formulario',
      });
    }

    const formData = new FormData();
    const formValue = this.formDMGroup.value;

    // Nombre duplicado
    const isDuplicated = this.dmGroups.some(
      (group) =>
        group.name === formValue.name && this.companyId === group.company_id
    );

    if (isDuplicated) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Ya existe un grupo con el mismo nombre',
      });
    }

    formData.append('name', formValue.name);
    formData.append('company_id', this.companyId);

    this.loadingForm = true;

    const requestObservable = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materiality/groups/${this.selectedTableGroup.id}`,
          formData
        )
      : this.serverService.sendData(`/api/dual-materiality/groups`, formData);

    const successDetail = this.edit
      ? 'El grupo de doble materialidad se ha actualizado correctamente'
      : 'El grupo de doble materialidad se ha creado correctamente';

    requestObservable.subscribe({
      next: (response) => {
        if (response.data) {
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: successDetail,
          });
          this.formDMGroup.reset();
          this.dialogGroupForm = false;
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error('Error al enviar formulario', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Ocurrió un error registrando el grupo, inténtelo de nuevo',
        });
        this.loadingForm = false;
      },
    });
  }

  /**
   * Observador de archivos para el componente p-fileUpload
   */
  getUploadedFiles(step: number, section: number): File[] {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      return [this.uploadedFiles[step][section]];
    }
    return [];
  }

  /**
   * Manejador de subida de archivo
   * @param event
   */
  onFileSelect(event: any, step: number, section: number): void {
    const selectedFiles = event.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (!this.uploadedFiles[step]) {
        this.uploadedFiles[step] = {};
      }
      this.uploadedFiles[step][section] = selectedFile;
    }
  }

  /**
   * Manejar eliminación de archivo
   * @param event
   */
  deleteFileSelected(event: any, step: number, section: number): void {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      delete this.uploadedFiles[step][section];
    }
  }

  /**
   * Eliminar un grupo de doble materialidad
   */
  deleteGroup(group) {
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.dialogDeleteGroup = true;
    }
  }

  handleCloseMatrixDialog(){
    this.matrixGroupData = {};
  }

  /**
   * Mostrar modal de formulario de creación/edición de registro de doble materialidad
   */
  async showDualMaterialityFormDialog(group: any, dm?: any) {
    try {
      this.loadingModal = true;
      this.selectedGroup = group.id;
      this.edit = !!dm;

      if (dm) { // Editar dm
        const response = await firstValueFrom(
          this.serverService.getData(
            `/api/dual-materialities/${dm.id}`
          )
        );

        if (response?.data) {
          await this.patchForms(response.data);
        }
      }

      // Sino, crear dm
      this.dialogDualMaterialityForm = true;
      this.dialogDualMateriality = false;
      this.dialogBulkToIndividualUpdate = false;
    } catch (err) {
      console.error(
        'Error al obtener listado de doble materialidad con indicadores',
        err
      );
    } finally {
      this.loadingModal = false; // Asegura que se desactiva siempre.
    }
  }

  /**
   * Procesado de formularios según indicador seleccionado
   */
  async patchForms(dm: any): Promise<void> {
    try {
      this.selectedDm = dm;
      this.dmName = dm.name;

      // Step1
      if (dm?.dual_mat1) {
        this.form1.patchValue({
          regulations_text: dm.dual_mat1.regulations_text || '',
          business_text: dm.dual_mat1.business_text || '',
          understanding_stakeholders_text:
            dm.dual_mat1.understanding_stakeholders_text || '',
        });
      }

      // Step2
      if (dm?.dual_mat2) {
        this.form2.patchValue({
          internal_consultations_text:
            dm.dual_mat2.internal_consultations_text || '',
          external_consultations_text:
            dm.dual_mat2.external_consultations_text || '',
        });
      }

      // Step5
      if(dm.dual_mat3){
        this.form5.patchValue({
          ...dm.dual_mat3, 
          impact: dm.dual_mat3.impact ? Number(dm.dual_mat3.impact) : null,
          financial_relevance: dm.dual_mat3.financial_relevance ? Number(dm.dual_mat3.financial_relevance) : null
        });
      }

      // Stakeholders
      if(dm['dual_mat_stakeholder'].length > 0) {
        const stakeholdersArray = this.formStakeholders.get('stakeholders') as FormArray;
        stakeholdersArray.clear();

        dm['dual_mat_stakeholder'].forEach((stakeholder) => {
          stakeholdersArray.push(this.createStakeholderForm(stakeholder));
        });
      }

      // Impactos
      if(dm['dual_mat_impact'].length > 0){
        this.impactRiskOportunity = dm['dual_mat_impact'].map((item, index) => {
          let upstreamDownstream: any = null;
          let financialMateriality: any = null;

          // Función para validar valores numéricos
          const validateValue = (value: any): number | null => {
            const numericValue = Number(value);
            return this.numberScale.some(
              (scale) => scale.value === numericValue
            )
              ? numericValue
              : null;
          };

          if (item['dual_mat_impact_updown'][0]) {
            upstreamDownstream = {
              id: item['dual_mat_impact_updown'][0].id,
              dual_materiality_indicator_id: item['dual_mat_impact_updown'][0].dual_materiality_indicator_id,
              magnitude: validateValue(item['dual_mat_impact_updown'][0].magnitude),
              irremediability: validateValue(item['dual_mat_impact_updown'][0].irremediability),
              material_impact: item['dual_mat_impact_updown'][0].material_impact ? true : false,
              scope: validateValue(item['dual_mat_impact_updown'][0].scope),
            };
          }

          if (item['dual_mat_impact_lca'][0]) {
            financialMateriality = {
              id: item['dual_mat_impact_lca'][0].id,
              dual_materiality_indicator_id: item['dual_mat_impact_lca'][0].dual_materiality_indicator_id,
              magnitude: validateValue(item['dual_mat_impact_lca'][0].magnitude),
              irremediability: validateValue(item['dual_mat_impact_lca'][0].irremediability),
              material_impact: item['dual_mat_impact_lca'][0].material_impact ? true : false,
              scope: validateValue(item['dual_mat_impact_lca'][0].scope),
            };
          }

          return {
            ...item,
            id: item.id,
            impact_form_impact_text: item.impact_text,
            impact_form_opportunity_text: item.opportunity_text,
            impact_form_risk_text: item.risk_text,
            financialMateriality: financialMateriality,
            upstreamDownstream: upstreamDownstream,
          };
        });

        // Operador de propagación 
        this.impactRiskOportunity = [...this.impactRiskOportunity];

        // Construir matriz de riesgos del formulario
        this.getImpactRiskMatrixData(this.impactRiskOportunity);
      }

      // Construir matriz de paso 3 con datos del paso 2
      if(dm['dual_mat_stakeholder'].length > 0){
        this.getStep2MatrixData(dm['dual_mat_stakeholder']);
      }

      // Mapear formulario dinamico
      this.dynamicFormArrayStep7 = dm['dual_mat_field'].map((item) => {
        return {
          ...item,
          label_field: item.label_field,
          value: item.value,
          file: null, // Inicializa el archivo como null
          fileName: '', // Inicializa el nombre del archivo como vacío
        };
      });
      
      // Manejar los documentos existentes del paso 1, 2 y 5 del formulario
      this.populateExistingFiles(dm);
    } catch (err) {
      console.error(
        'Error al procesar los formularios de doble materialidad',
        err
      );
    }
  }

  /**
   * Construcción de matriz del paso 3 del formulario de doble materialidad
   */
  getStep2MatrixData(dmStakeholder) {
    // Inicializamos la estructura de datos para la matriz
    this.matrixGroupData = {
      matrixData: {},
    };

    // Iteramos sobre los registros en el array
    dmStakeholder.forEach((item) => {
      // Convertimos impact_risks e influence_level a números
      const impactRisks = Number(item.impact_risks);
      const influenceLevel = Number(item.influence_level);

      // Validamos que estén dentro del rango esperado (1-5)
      if (
        impactRisks >= 1 &&
        impactRisks <= 5 &&
        influenceLevel >= 1 &&
        influenceLevel <= 5
      ) {
        // Inicializamos la celda correspondiente si no existe
        if (!this.matrixGroupData.matrixData[impactRisks]) {
          this.matrixGroupData.matrixData[impactRisks] = {};
        }
        if (!this.matrixGroupData.matrixData[impactRisks][influenceLevel]) {
          this.matrixGroupData.matrixData[impactRisks][influenceLevel] = [];
        }

        // Agregamos el registro a la celda correspondiente
        this.matrixGroupData.matrixData[impactRisks][influenceLevel].push({
          id: item.id,
          name: item.name,
          description: item.description,
          priority: item.priority,
          total: item.total,
        });
      }
    });
  }

  /**
   * Función para procesar documentos existentes desde el registro de un indicador
   */
  populateExistingFiles(dm: any): void {
    // Inicializar existingFiles
    this.existingFiles = {};

    // Paso 1
    if (dm.dual_mat1) {
      const step = 1;
      this.existingFiles[step] = {};

      if (dm.dual_mat1.regulations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_mat1.regulations_document,
          name: 'regulations_document.pdf', // Puedes obtener el nombre real si está disponible
        };
      }

      if (dm.dual_mat1.business_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_mat1.business_document,
          name: 'business_document.pdf',
        };
      }

      if (dm.dual_mat1.understanding_stakeholders_document) {
        this.existingFiles[step][3] = {
          url: dm.dual_mat1.understanding_stakeholders_document,
          name: 'understanding_stakeholders_document.pdf',
        };
      }
    }

    // Paso 2
    if (dm.dual_mat2) {
      const step = 2;
      this.existingFiles[step] = {};

      if (dm.dual_mat2.internal_consultations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_mat2.internal_consultations_document,
          name: 'internal_consultations_document.pdf',
        };
      }

      if (dm.dual_mat2.external_consultations_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_mat2.external_consultations_document,
          name: 'external_consultations_document.pdf',
        };
      }
    }

    // Paso 3
    if (dm.dual_mat3) {
      const step = 3;
      this.existingFiles[step] = {};

      if (dm.dual_mat3.impact_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_mat3.impact_document,
          name: 'impact_document.pdf',
        };
      }

      if (dm.dual_mat3.financial_relevance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_mat3.financial_relevance_document,
          name: 'financial_relevance_document.pdf',
        };
      }
    }
  }

  /**
   * Eliminar un registro de doble materialidad de un indicador
   */
  deleteDm(dm) {
    this.dialogDeleteDm = true;
    this.selectedDm = dm;
  }

  /**
   * Función auxiliar para selección de eliminación de doble materialidad
   * @param option
   */
  onClickDeleteDm(option: boolean) {
    this.loadingModalBtn = true;
    if (option) {
      this.serverService
        .deleteData(`/api/dual-materialities/${this.selectedDm.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.getDualMaterialitiesByGroup();

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail:
                  'Registro de doble materialidad eliminado correctamente',
              });
            }

            this.dialogDeleteDm = false;
            this.loadingModalBtn = false;
            this.selectedDm = null;
          },
          error: (err) => {
            this.loadingModalBtn = false;
            console.error('Error al eliminar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el registro de doble materialidad, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteDm = false;
    }
  }

  /**
   * Función auxiliar para selección de eliminación de grupo de doble materialidad
   * @param option
   */
  onClickDeleteGroup(option: boolean) {
    if (option) {
      this.loadingModalBtn = true;
      this.serverService
        .deleteData(`/api/dual-materiality/groups/${this.selectedGroup.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.dialogDeleteGroup = false;
              this.dialogGroup = false;
              this.loadingModalBtn = false;
              this.selectedGroup = null;

              this.getDualMaterialitiesByGroup();
              this.groupsWithDmData = [...this.groupsWithDmData];

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Grupo de doble materialidad eliminado correctamente',
              });
            }
          },
          error: (err) => {
            this.loadingModalBtn = false;
            console.error('Error al eliminar el grupo', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el grupo, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteGroup = false;
    }
  }

  /**
   * Abrir documento desde formulario
   */
  openDocument(document) {
    if (document.url) {
      window.open(document.url, '_blank');
    } else {
      window.open(document, '_blank');
    }
  }

  /**
   * Actualizar campos del formulario de paso 7
   */
  updateStep7FormFields() {
    if (this.label_field === '') {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe ingresar un título para el campo del formulario',
      });
    }

    const data = {
      label_field: this.label_field,
      value: null,
    };

    this.dynamicFormArrayStep7.push(data);
    this.label_field = '';
  }

  /**
   * Eliminar campo del formulario
   */
  deleteFormField(field: any): void {
    const index = this.dynamicFormArrayStep7.indexOf(field); // Obtengo indice del campo seleccionado con indexOf

    if (index > -1 && this.edit) {
      // API para eliminar este registro
      this.serverService
        .deleteData(
          `/api/dual-materiality/field/${field.id}`
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.dynamicFormArrayStep7.splice(index, 1); // Elimino campo concreto
              this.messageService.add({
                severity: 'success',
                summary: 'Éxito',
                detail: 'Campo eliminado correctamente',
              });
            }
          },
          error: (err) => {
            console.error('Error al eliminar el campo', err);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo eliminar el campo',
            });
          },
        });
    } else if (!this.edit && index > -1){
      this.dynamicFormArrayStep7.splice(index, 1); // Elimino campo concreto
    }
  }

  onFileUpload(event: any, item: any): void {
    const file = event.files[0]; // Obtiene el archivo subido
    item.file = file; // Almacena el archivo en el objeto correspondiente
    item.fileName = file.name; // Guarda el nombre del archivo
  }

  /**
   * Mostrar modal de creación/edición de impacto, riesgo y oportunidad
   */
  showImpactRegisterDialog(item?) {
    if (item) {
      // Indicadores
      if(item.id){ // Es un indicador creado previamente
        this.selectedImpactRegister = item;
        this.selectedIndicators = item.dual_mat_impact_indicator.map(indicator => indicator.indicator_id);
      } else {
        this.selectedIndicators = item['indicators_selected'];
      }

      this.formImpact.patchValue({
        ...item,
        indicators_selected: this.selectedIndicators
      });
    }
    this.editImpact = item ? true : false;
    this.dialogImpactRisk = true;
  }

  /**
   * Manejar eliminación de producto
   * @param prouct
   */
  deleteImpact(event, item) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        if(item.id){
          this.serverService
          .deleteData(
            `/api/dual-materiality/impact/${item.id}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                // Elimino localmente el registro correspondiente si la respuest es ok del api
                const index = this.impactRiskOportunity.indexOf(item);

                if (index > -1) {
                  this.impactRiskOportunity.splice(index, 1); // Elimino campo concreto

                  // Construir matriz de riesgos del formulario
                  this.getImpactRiskMatrixData(this.impactRiskOportunity);

                  this.messageService.add({
                    severity: 'success',
                    summary: 'OK',
                    detail: 'Registro eliminado correctamente',
                  });
                }
              }
            },
            error: (err) => {
              console.error('Error al eliminar el registro', err);
              this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail:
                  'Ocurrió un error al eliminar el registro, inténtelo de nuevo',
              });
            },
          });
        } else {
          const index = this.impactRiskOportunity.indexOf(item);

          if (index > -1) {
            this.impactRiskOportunity.splice(index, 1); // Elimino campo concreto

            // Construir matriz de riesgos del formulario
            this.getImpactRiskMatrixData(this.impactRiskOportunity);
            
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Registro eliminado correctamente',
            });
          }
        }
      },
      reject: () => {},
    });
  }

  /**
   * Manejar cierre de modal de impacto
   */
  handleCloseImpactDialog() {
    this.selectedImpactRegister = null;
    this.formImpact.reset();
    this.selectedIndicators = [];
  }

  /**
   * Manejar guardado local de registro de impactos (se enseña aviso que se guardará en paso 7)
   */
  onPreviewSubmitImpactRegister() {
    this.formImpact.markAllAsTouched();

    if (this.formImpact.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Comprobar que hay indicadores seleccionados
    if (this.selectedIndicators.length === 0) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Seleccione al menos un indicador de la lista',
      });
    }

    // Crear dm e impacto
    if ((!this.editImpact && !this.edit) || (!this.editImpact && this.edit)) {
      this.dialogPreviewSubmit = true;

      // Añadir al formulario
      this.formImpact.patchValue({
        indicators_selected: this.selectedIndicators
      });
    }

    // Crear dm y editar impacto en local
    if(!this.edit && this.editImpact) {

    }

    // Editar dm y editar impacto existente (solo campos de texto)
    if (this.edit && this.editImpact) {    
      const formData = new FormData();
      const formValue = this.formImpact.value;
    
      // Validar campos antes de agregarlos
      if (formValue.impact_form_impact_text) {
        formData.append('impact_text', formValue.impact_form_impact_text);
      }
    
      if (this.uploadedFilesImpact) {
        formData.append('impact_document', this.uploadedFilesImpact);
      }
    
      if (formValue.impact_form_risk_text) {
        formData.append('risk_text', formValue.impact_form_risk_text);
      }
    
      if (this.uploadedFilesRisk) {
        formData.append('risk_document', this.uploadedFilesRisk);
      }
    
      if (formValue.impact_form_opportunity_text) {
        formData.append('opportunity_text', formValue.impact_form_opportunity_text);
      }
    
      if (this.uploadedFilesOpportunity) {
        formData.append('opportunity_document', this.uploadedFilesOpportunity);
      }

      // Procesado de actualización impacto de forma individual
      this.loadingForm = true;
      this.serverService.sendData(`/api/dual-materiality/impact/${this.selectedImpactRegister?.id}`, formData).subscribe({
        next: async (response) => {
          if(response.data){
            // Actualización correcta
            const responseShowDm = await firstValueFrom(
              this.serverService.getData(
                `/api/dual-materialities/${this.selectedDm.id}`
              )
            );
    
            if (responseShowDm?.data) {
              await this.patchForms(responseShowDm.data);
            }

            this.messageService.add({
              severity:'success',
              summary: 'Éxito',
              detail: 'Registro actualizado correctamente',
            });

            this.dialogImpactRisk = false;
            this.loadingForm = false;
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al actualizar el impacto', error);
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: 'Ocurrió un error al actualizar el impacto, inténtelo de nuevo',
          });
        }
      })
    }
  }

  /**
   * Manejar cierre de modal de formulario de impacto, riesgo y oportunidad
   */
  closeModalImpactForm() {
    this.dialogPreviewSubmit = false;
    this.dialogImpactRisk = false;

    // Guardado local de registro
    this.impactRiskOportunity.push({
      ...this.formImpact.value,
      impact_form_impact_document: this.uploadedFilesImpact,
      impact_form_risk_document: this.uploadedFilesRisk,
      impact_form_opportunity_document: this.uploadedFilesOpportunity,
      upstreamDownstream: {
        magnitude: null,
        scope: null,
        irremediability: null,
        material_impact: null,
      },
      financialMateriality: {
        magnitude: null,
        scope: null,
        irremediability: null,
        material_impact: null,
      },
    });

    this.formImpact.reset();
    this.uploadedFilesImpact = null;
    this.uploadedFilesRisk = null;
    this.uploadedFilesOpportunity = null;

    // Operador de propagación para crear una nueva referencia
    this.impactRiskOportunity = [...this.impactRiskOportunity];
  }

  /**
   * Manejar documentos seleccionados para formulario de impactos
   * @param event
   * @param type
   * @returns
   */
  onFileSelectImpact(event: any, type: string) {
    let file = event.files[0];
    if (!file) return;

    switch (type) {
      case 'impact':
        this.uploadedFilesImpact = file;
        file = null;
        break;
      case 'risk':
        this.uploadedFilesRisk = file;
        file = null;
        break;
      case 'opportunity':
        this.uploadedFilesOpportunity = file;
        file = null;
        break;
    }
  }

  deleteFileSelectedImpact(type: string) {
    switch (type) {
      case 'impact':
        this.uploadedFilesImpact = null;
        break;
      case 'risk':
        this.uploadedFilesRisk = null;
        break;
      case 'opportunity':
        this.uploadedFilesOpportunity = null;
        break;
    }
  }
  
  /**
   * Función para obtener label de ejes de matriz de riesgo según valor
   */
  getAxisLabel(value: number): string {
    const labelsMap = new Map<number, string>([
      [1, 'Muy bajo'],
      [2, 'Bajo'],
      [3, 'Medio'],
      [4, 'Alto'],
      [5, 'Muy alto'],
    ]);
  
    return labelsMap.get(value) || '';
  }

    /**
   * Función para obtener label de ejes de matriz de riesgo según valor
   */
    getAxisLabelImpact(value: number): string {
      const labelsMap = new Map<number, string>([
        [5, 'Muy alto'],
        [4, 'Alto'],
        [3, 'Medio'],
        [2, 'Bajo'],
        [1, 'Muy bajo'],
      ]);
    
      return labelsMap.get(value) || '';
    }

  /**
   * Modal de aviso para edición masiva
   */
  showBulkEditModal(group, dm){
    this.selectedDm = dm;
    this.dialogBulkEdit = true;
    this.selectedGroup = group;
    this.edit = !!dm;

    // Busco todos los indicadores que pertenecen al seleccionado (dm)
    this.indicatorsByDmId = group['aggregatedIndicators'].filter(item => item.dual_materiality_id === dm.dual_materiality_id);
  }

  /**
   * Manejar cierre de modal de edición masiva
   */
  handleCloseBulkEdit(){
    this.selectedDm = null;
    this.selectedGroup = null;
    this.form1.reset();
    this.form2.reset();
    this.form5.reset();
  }

  /**
   * Manejo para mostrar modal de edición de registro de doble materialidad
   */
  showUpdateModal(group, dm){
    this.groupSelected = group;
    this.showDualMaterialityFormDialog(group, dm);
  }

  /**
   * Manejo de modal para confirmar edición masiva a individual
   */
  confirmEditToIndividual(){
    this.bulkToIndividualConfirmed = true;
    this.showDualMaterialityFormDialog(this.groupSelected, this.selectedDm);
  }

  /**
   * Reinicio de varios datos agrupados
   */
  refreshData(){
    this.dialogDualMaterialityForm = false;
    this.getDualMaterialitiesByGroup();
    this.uploadedFilesImpact = null;
    this.uploadedFilesRisk = null;
    this.uploadedFilesOpportunity = null;
    this.uploadedFiles = {};
    this.groupSelected = null;
    this.selectedDm = null;
    this.bulkToIndividualConfirmed = false;
  }

  /**
   * Abrir documento de reporte de doble materialidad según grupo seleccionado
   */
  showReportDocumentConfirmation(group){
    if(group){
      //buscar contenido grupo seleccionado
      const currentDualMatRegistry = this.groupsWithDmData.find(objeto => objeto.id === group.id);
      //console.log(currentDualMatRegistry);      
      if(currentDualMatRegistry.dualMat.length === 0){
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'No hay datos para generar el documento word.',
          life: 5000,
        });
      }

      this.selectedReportGroup = group;
      this.dialogConfirmationReportDocument = true;
    }
  }  

  /**
   * Procesado de datos para generar o no reporte de grupo de doble materialidad
   */
  async generateReportDocument(option){
    this.loadingReport = true;

    if(option){
        this.serverService.getData(`/api/dual-materiality/groups/${this.selectedReportGroup?.id}/getWord`).subscribe({
          next: (response) => {
            if(response){
              const openDocument = response[0];
              if(!openDocument){
                this.loadingReport = false;
                return this.messageService.add({
                  severity: 'warn',
                  summary: 'Aviso',
                  detail: 'Error al generar el documento word.',
                  life: 5000,
                });
              } else {
                window.open(openDocument)
                this.handleCloseDialogConfirmationReportDocument();
                return this.messageService.add({
                  severity: 'success',
                  summary: 'Descarga exitosa',
                  detail: 'El archivo Word ha sido generado y descargado',
                });                
              }
            }
            
          },
          error: (err) => {
            this.loadingReport = false;
            console.error('Error al generar informe de doble materialidad', err);
          }
        })
    } else {
      this.handleCloseDialogConfirmationReportDocument();
    }
  }
  

  /**
   * Manejo de cierre de modal de confirmación de modal de reporte de doble materialidad
   */
  handleCloseDialogConfirmationReportDocument(){
    this.dialogConfirmationReportDocument = false;
    this.loadingReport = false;
  }

  /**
   * Generar captura de pantalla de matriz
   */
  async generateMatrixImg(captureElement: any) {
    if (captureElement instanceof HTMLElement) {
      try {
        // Obtener canvas mediante html2canvas y excluir elementos específicos
        const canvas = await html2canvas(captureElement, {
          ignoreElements: (element) => {
            return (
              element.classList.contains('vertical-header') || 
              element.classList.contains('horizontal-header')
            );
          }
        });
  
        // Convertir el canvas a Blob
        const blob = await this.toBlobPromise(canvas, 'image/png');
        return blob;
      } catch (error) {
        console.error('Error durante la captura del elemento:', error);
      }
    }
  
    return null;
  }

  async toBlobPromise(
    canvas: HTMLCanvasElement,
    type: string
  ): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to convert canvas to Blob'));
        }
      }, type);
    });
  }

  /**
   * Manejo de modal para ver listado de impactos materiales
   */
  showMaterialImpacts(){
    this.materialImpacts = this.impactRiskOportunity.filter(item =>
      item.upstreamDownstream.material_impact && item.financialMateriality.material_impact
    )
    this.showMaterialImpactsDialog = true;
  }

  /**
   * Generar datos para matriz de riesgos de impactos creados
   */
  async getImpactRiskMatrixData(impactRiskArray: any[]): Promise<void> {
    // Inicializamos impactMatrixGroupData
    this.impactMatrixGroupData = {
      matrixData: {},
    };
  
    // Iteramos sobre impactRiskArray
    impactRiskArray.forEach((item) => {
      const financialMateriality = item.financialMateriality || {};
      const upstreamDownstream = item.upstreamDownstream || {};

        // Obtener las puntuaciones promedio de financialMateriality
        const financialRelevance = Math.ceil(
          ((financialMateriality.magnitude || 0) +
            (financialMateriality.scope || 0) +
            (financialMateriality.irremediability || 0)) /
            3
        );
    
        // Obtener las puntuaciones promedio de upstreamDownstream
        const impact = Math.ceil(
          ((upstreamDownstream.magnitude || 0) +
            (upstreamDownstream.scope || 0) +
            (upstreamDownstream.irremediability || 0)) /
            3
        );
    
        // Validar que las puntuaciones estén dentro del rango
        if (
          financialRelevance >= 1 &&
          financialRelevance <= 5 &&
          impact >= 1 &&
          impact <= 5
        ) {
          // Si la celda aún no existe, inicializarla
          if (!this.impactMatrixGroupData.matrixData[impact]) {
            this.impactMatrixGroupData.matrixData[impact] = {};
          }
    
          if (!this.impactMatrixGroupData.matrixData[impact][financialRelevance]) {
            this.impactMatrixGroupData.matrixData[impact][financialRelevance] = [];
          }
      
          // Agregar el registro a la celda correspondiente
          this.impactMatrixGroupData.matrixData[impact][financialRelevance].push(item);
        }
    });
  }

  /**
   * Manejar modal para mostrar niveles e indicadores de una doble materialidad
   */
  showIndicatorsByDmLevel(dmSelected){
    this.dmSelected = dmSelected;
    this.showIndicatorsByDmLevelDialog = true;
  }

  /**
   * Manejar cierre de modal de niveles e indicadores de una doble materialidad
   */
  handleCloseIndicatorsByDmLevelDialog(){
    this.dmSelected = null;
  }

  showFieldUpdateDialog(matField){
    this.matFieldSelected = matField;
    if(matField){
      this.formUpdateField.patchValue(matField);
    }
    this.dialogFieldSelected = true;
  }

  handleCloseMatFieldDialog(){
    this.matFieldSelected = null;
    this.dialogFieldSelected = false;
    this.updateFieldDocument = null;
  }

  onSubmitMatFields(){
    this.formUpdateField.markAllAsTouched();

    if(this.formUpdateField.invalid){
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise el formulario',
      })
    }

    const formData = new FormData();
    const formValue = this.formUpdateField.value;

    formData.append('label_field', formValue.label_field);
    formData.append('value', formValue.value);
    if(this.updateFieldDocument){
      formData.append('document', this.updateFieldDocument);
    }

    this.loadingForm = true;

    this.serverService.sendData(`/api/dual-materiality/field/${this.matFieldSelected.id}`, formData).subscribe({
      next: (response) => {
        if(response.data){
          this.messageService.add({
            severity:'success',
            summary: 'Éxito',
            detail: 'Campo actualizado correctamente',
          });
          this.loadingForm = false;
          this.dialogFieldSelected = false;
          this.dialogDualMaterialityForm = false;
        }
      },
      error: (error) => {
        console.error('Error al obtener los datos del campo', error);
        this.loadingForm = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Error al actualizar el campo',
        })
      }
    })
  }

  onFileUploadFields(event: any, item: any): void {
    const file = event.files[0]; // Obtiene el archivo subido
    this.updateFieldDocument = file;
  }

  /**
   * Manejar cierre de modal con tabla de valores de paso 2
   */
  handleCloseDialogStep2Table(){
    
  }

  /**
   * Mostrar modal de tabla de valores de paso 2
   */
  showStep2Table(){
    this.dialogStep2Table = true;
    this.stakeholdersSelected = this.formStakeholders.value['stakeholders'];
  }
}
