<p-dialog
  [draggable]="true"
  [(visible)]="isModalOpen"
  [modal]="false"
  [resizable]="false"
  [position]="'bottom-right'"
  (onHide)="isModalOpen = false"
  [style]="{ width: '50vw', height: '60vh'}"
  (onHide)="handleCloseChatAI()"
>
  <ng-container #dialogContainer>
    <ng-template pTemplate="header">
      <div class="header-container">
        <p-avatar
          image="../assets/images/CGarcia.png"
          shape="circle"
        />
        <span class="font-bold white-space-nowrap">
          Clara GarcIA
        </span>
        <!-- OCULTADO TEMPORALMENTE - PENDIENTE SOCKET ENTRE VENTANAS DE CHAT -->
        <!-- <i class="c-pointer pi pi-external-link" (click)="openDialogInNewWindow()"></i> -->
      </div>
    </ng-template>

    <!-- Contenido del modal: indicador narrativo -->
    @if(qualitativeIndicatorSelected){
      <div class="box-messages">
        <p class="box-messages__bot box-options">
          ¡Hola! 🚀 ¿Qué puedo hacer por ti? Indícame cómo puedo ayudarte
          <button
            *ngFor="let option of options; let i = index"
            class="box-messages__option"
            [ngClass]="{
              'button-error': optionError,
              'button-success': selectedOption === i
            }"
            type="button"
            (click)="setOption(i)"
          >
          {{ option }}
          </button>
        </p>
        <p *ngFor="let message of messagesNarrative" [ngClass]="message.class"  [innerHTML]="message.text"></p>
        <p
          *ngIf="canInsertResponse"
          class="box-messages__bot box-options"
        >
          ¿Quieres realizar alguna de las siguientes acciones?
          <button
            *ngFor="let handler of userResponseHandler; let i = index"
            class="box-messages__option"
            type="button"
            (click)="doHandler(i)"
          >
          {{ handler }}
          </button>
        </p>
        <div *ngIf="isLoadingContent" class="box-messages__bot box-messages__bot-loading">
          <div class="loader"></div>
        </div>
      </div>
    } @else if(!qualitativeIndicatorSelected){ <!-- Contenido del modal: general -->
      <div #messagesContainer class="chat-messages">
        <div class="chat-messages__bot">
          <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
          <p>
            ¡Hola! Soy Clara GarcIA, tu consultora digital en GRC. 👩‍💼Estoy aquí para ayudarte a gestionar todo lo relacionado con Gobernanza, Riesgos y Cumplimiento. Mi objetivo es hacer que tus procesos sean más fáciles, eficientes y alineados con las normativas.
          </p>
        </div>

        <div class="chat-messages__bot">
          <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
          <p >
            🚀 ¿Qué puedo hacer por ti? Selecciona la opción deseada
            <button
              *ngFor="let suggestion of suggestions; let i = index"
              class="chat-messages__suggestion block"
              [ngClass]="{
                'button-error': suggestionError,
                'button-success': currentSuggestion.includes(i)
              }"
              type="button"
              (click)="setSuggestion(i, suggestion)">
              {{ suggestion }}
            </button>
          </p>
        </div>

        <div class="chat-messages__bot">
          <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
          <div>
            <p>Tras seleccionar los temas propuestos, haga clic en enviar para recibir una respuesta sobre los temas seleccionados. Si lo desea, puede agregar un mensaje adicional en el cuadro de texto para obtener información más detallada acerca de los temas solicitados.</p>
          </div>
        </div>

        <div *ngFor="let message of messages" [ngClass]="message.class" >
          <img
            *ngIf="message.class == 'chat-messages__bot'"
            src="../assets/images/CGarcia.png"
            alt="Avatar"
            class="chat-messages__avatar contentImg"
          />
          <p [innerHTML]="message.text"></p>
        </div>

        <!-- <div class="chat-messages__bot">
          <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
          <div>
            <p>Si en algún momento necesitas más detalles o tienes una consulta específica, ¡no dudes en preguntarme! Estoy aquí para ser tu aliada en el camino hacia la excelencia en GRC.</p>
            <br>
            <p>¿Cómo puedo ayudarte hoy? 💡</p>
          </div>
        </div> -->

        <div
          *ngIf="isLoading"
          class="chat-messages__bot chat-messages__bot-loading"
        >
          <div class="loader"></div>
        </div>
      </div>
    }

    <!-- Footer para input de chat -->
    <ng-template pTemplate="footer">
      @if(qualitativeIndicatorSelected && selectedOption === 1){
        <div class="chat-input">
          <ion-item lines="none">
            <ion-textarea
              [(ngModel)]="userMessage"
              placeholder="Escribe un mensaje..."
            ></ion-textarea>
            <ion-icon name="send" (click)="executeOption()"></ion-icon>
          </ion-item>
        </div>
      } @else if(!qualitativeIndicatorSelected){
        <div class="chat-input">
          <ion-item lines="none">
            <ion-textarea
              [(ngModel)]="userMessage"
              placeholder="Escribe un mensaje..."
            ></ion-textarea>
            <ion-icon name="send" (click)="sendMessage()"></ion-icon>
          </ion-item>
        </div>

        <p class="text-center my-3">
          <small>Los chats del área de trabajo icloudCompliance no se usan para formar a nuestros modelos. Clara puede cometer errores.</small>
        </p>
      }

    </ng-template>
  </ng-container>
</p-dialog>

<!-- Botón flotante para abrir chat AI -->
<ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!isModalOpen" class="bottompos">
  <ion-fab-button (click)="openModal()">
    <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-avatar contentImg" />
  </ion-fab-button>
</ion-fab>
