import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { ServerService } from '../services/server.service';
import { PermissionsService } from '../services/permissions.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { EventsService } from '../services/events.service';
import { MenuController, ModalController } from '@ionic/angular';
import { MenuComponent } from '../components/menu/menu.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './app.main-menu.component.html',
})
export class AppMainMenuComponent implements OnInit {
  model: any[] = [];
  test = 'clase-test';
  menuData: any;
  companyId: any;
  user: any;
  userByCompany: any;
  loading: boolean = true;
  private eventSubscription: Subscription;
  isSidebarCollapsed = false;

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService,
    private permissionsService: PermissionsService,
    private eventService: EventsService,
    private menu: MenuController,
    private modalCtrl: ModalController
  ) {
    this.companyId = localStorage.getItem('companyId');
  }

  async ngOnInit() {
    await this.getMainMenuByCompanyId();

    //Suscripción al evento que se ha emitido, se mantiene a la escucha desde que el componente está activo
    this.eventSubscription = this.eventService.events$.subscribe((event) => {
      if (event.event === 'menuUpdated') {
        this.getMainMenuByCompanyId();
      }
    });
  }

  ngOnDestroy() {
    // if (this.eventSubscription) {
    //   this.eventSubscription.unsubscribe();
    // }
  }

  onSidebarToggle() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  async showModalMenu() {
    const modal = await this.modalCtrl.create({
      cssClass: 'alert-modal',
      component: MenuComponent,
      componentProps: {},
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
    }
  }

  /**
   * Obtener menú lateral según un id de empresa
   */
  async getMainMenuByCompanyId() {
    this.serverService.getData(`/api/main-menus/`).subscribe({
      next: async (response) => {
        if (response.data) {
          this.model = this.transformMenuData(response.data);
        }

        await this.addAditionalItems(false);
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.addAditionalItems(true);
      },
    });
  }

  /**
   * Procesado de respuesta del menú
   * @param menu
   */
  transformMenuData(data: any): any[] {
    return Object.values(data).map((menu: any) => 
      this.buildNode(menu)
    );
  }

  /**
   * Procesado del menú para montar el menú con respecto al diseño actual
   * @param menu
   * @returns
   */
  buildNode(menu: any): any {
    const element = menu?.element || menu; // Usar 'element' si existe, caso contrario, usar 'menu'
    const {
      id,
      description,
      disabled,
      parent_id,
      parent,
      label,
      icon,
      main_menu_indicators,
      main_menu_hierarchy_levels_id,
    } = element;

    const children = menu?.items
      ? Array.isArray(menu?.items)
        ? menu?.items?.map((subMenu: any) => this.buildNode(subMenu)) // Si es un array
        : Object.values(menu?.items).map((subMenu: any) =>
            this.buildNode(subMenu)
          ) // Si es un objeto
      : null;

    return {
      id,
      label,
      disabled,
      description,
      main_menu_hierarchy_levels_id,
      parent,
      parent_id,
      icon,
      items: children,
      data: { indicators: main_menu_indicators },
    };
  }

  /**
   * Agrega elementos adicionales al array de datos del menú.
   */
  async addAditionalItems(noData?: boolean) {
    if (noData) {
      this.model = [];
    }

    // Check a true para lectura en menú de opciones de permisos de indicadores
    await this.getUserByCompany();

    const dashboard = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      added: true,
      items: [
        {
          label: 'Acceder',
          icon: 'null',
          routerLink: ['/dashboard'],
        },
      ],
    };

    this.model.unshift(dashboard); // Agregar nuevo elemento al inicio

    //variable Ajustes (control acceso por permisos)
    let ajustes: any;
    let companyItems: any;

    //check ajustes = usuarios que puedan editar indicadores (superadmin y admin)
    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 2, 3)
    ) {
      ajustes = {
        label: 'Ajustes',
        icon: 'pi pi-cog',
        items: [
          {
            label: 'Menú',
            icon: null,
            items: [],
          },
        ],
      };

      //check section Menu. ajustes > Marcadores (superadmin)
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 1, 2)
      ) {
        ajustes.items[0].items.push({
          label: 'Estructura',
          icon: null,
          routerLink: ['/manage-menu'],
          added: true,
        });
      }

      //check section Indicator. ajustes > Indicadores
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 2, 3)
      ) {
        ajustes.items[0].items.push({
          label: 'Indicadores',
          icon: null,
          added: true,
          routerLink: ['/indicadores'],
        });
      }

      //check section IndicatorPermission
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 3, 2)
      ) {
        ajustes.items.push({
          label: 'Permisos indicadores',
          icon: null,
          added: true,
          routerLink: ['/indicator-permissions'],
        });
      }

      //check section ActionPermission
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 9, 2)
      ) {
        ajustes.items.push({
          label: 'Permisos acciones',
          icon: null,
          added: true,
          routerLink: ['/action-permissions'],
        });
      }
    }

    // Aquí agregamos la lógica de permisos para "Repositorio de información"
    let repositorioInformacionItems: any[] = [];
    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 18, 1)
    ) {
      // repositorioInformacionItems.push({
      //   label: 'Contexto de la organización',
      //   icon: 'null',
      //   routerLink: ['/organization-context'],
      // });
    }

    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 19, 1)
    ) {
      // repositorioInformacionItems.push({
      //   label: 'Partes interesadas',
      //   icon: 'null',
      //   routerLink: ['/concerned-parties'],
      // });
    }

    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 20, 1)
    ) {
      // repositorioInformacionItems.push({
      //   label: 'Alcance del sistema',
      //   icon: 'null',
      //   routerLink: ['/system-scopes'],
      // });
    }

    // Info de tipos de usuarios en ESG
    if (this.user.id === 1) {
      repositorioInformacionItems.push(
        //   {
        //     label: 'Asociados',
        //     icon: 'null',
        //     routerLink: ['/users-associates'],
        //   },
        //   {
        //     label: 'Clientes',
        //     icon: 'null',
        //     routerLink: ['/users-customers'],
        //   },
        //   {
        //     label: 'Empleados',
        //     icon: 'null',
        //     routerLink: ['/users-employees'],
        //   },
        {
          label: 'Proveedores',
          icon: 'null',
          added: true,
          routerLink: ['/users-suppliers'],
        }
      );
    }

    if (repositorioInformacionItems.length > 0) {
      this.model.push({
        label: 'Repositorio de información',
        icon: 'pi pi-info-circle',
        items: repositorioInformacionItems,
      });
    }

    // Asignación de listado de productos de una empresa, inventario y ciclo de vida
    // HARDCODEAMOS A companyId = 1 y superadmin
    if (+this.companyId === 1 && +this.user.id === 1) {
      companyItems = {
        label: 'Gestión de empresa',
        icon: 'pi pi-building',
        items: [
          {
            label: 'Evaluación de impacto medioambiental',
            icon: null,
            items: [
              {
                label: 'Procedimiento para la identificación del proyecto',
                icon: null,
                added: true,
                routerLink: ['/project-identification'],
              },
              {
                label: 'Procedimiento para el alcance',
                icon: null,
                added: true,
                routerLink: ['/scoping'],
              },
              {
                label: 'Procedimiento para el estado del medio ambiente base',
                icon: null,
                added: true,
                routerLink: ['/study-base-environment'],
              },
              {
                label:
                  'Procedimiento para la identificación y evaluación de impactos',
                icon: null,
                added: true,
                routerLink: ['/identification-assessment-impacts'],
              },
              {
                label: 'Procedimiento para proponer medidas de mitigación',
                icon: null,
                added: true,
                routerLink: ['/proposing-mitigation-measures'],
              },
              {
                label: 'Procedimiento para la consulta pública',
                icon: null,
                added: true,
                routerLink: ['/public-consultation'],
              },
              {
                label:
                  'Procedimiento para la elaboración de informe de evaluación de impacto ambiental',
                icon: null,
                added: true,
                routerLink: ['/impact-assessment-report'],
              },
            ],
          },
          {
            label: 'Inventario de productos',
            icon: null,
            added: true,
            routerLink: ['/company-products'],
          },
          {
            label: 'Ciclo de vida (LCI)',
            icon: null,
            added: true,
            routerLink: ['/lifecycle'],
          },
          {
            label: 'Procesos',
            icon: null,
            added: true,
            routerLink: ['/processes'],
          },
          {
            label: 'Definición de objetivos y alcance',
            icon: null,
            added: true,
            routerLink: ['/scope-objectives-definition'],
          },
          {
            label: 'Analisis de inventario',
            icon: null,
            added: true,
            routerLink: ['/inventory-analysis'],
          },
        ],
      };

      //Push de companyItems si procede
      this.model.push(companyItems);
    }

    //Última asignación de menus a model['items']
    this.model.push(
      {
        label: 'Reportes',
        icon: 'pi pi-book',
        items: [
          {
            label: 'Panel',
            added: true,
            icon: 'null',
            routerLink: ['/panel'],
          },
        ],
      },
      {
        label: 'Doble materialidad',
        icon: 'pi pi-angle-double-right',
        items: [
          {
            label: 'Acceder',
            icon: 'null',
            added: true,
            routerLink: ['/dual-materiality'],
          },
        ],
      },
      // {
      //   label: 'Empresas',
      //   icon: 'pi pi-share-alt',
      //   items: [
      //     {
      //       label: 'Acceder',
      //       icon: 'null',
      //       routerLink: ['/business-networking'],
      //     },
      //   ],
      // },
      ajustes
    );
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await lastValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compaías de usuario:',
        err
      );
    } finally {
    }
  }
}
