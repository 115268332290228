<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
    <ion-row>
        <div class="header"></div>
    </ion-row>

    <ion-row style="margin-top: -6rem !important">
        <div class="word-container">
            @if(this.model && this.model.length > 0){ 
                @if(user && userByCompany &&
                    userByCompany?.sections && (user?.id === 1 || (user?.id !== 1 &&
                    permissionService.checkSectionPermission(userByCompany, 17, 1)))){
                    <button
                        pButton
                        (click)="handleFilterModal()"
                        [outlined]="true"
                        icon="pi pi-file-word"
                        label="Descargar informe Word"
                        [loading]="loadingModal"
                    ></button>
                }
                    
                @if(user && userByCompany &&
                    userByCompany?.sections && (user?.id === 1 || (user?.id !== 1 &&
                    permissionService.checkSectionPermission(userByCompany, 15, 1)))){
                    <button
                        pButton
                        (click)="downloadXBRLReport()"
                        [outlined]="true"
                        icon="pi pi-download"
                        label="Descargar informe Xbrl"
                        [loading]="loadingXbrl"
                    ></button>
                }     

                <button
                    pButton
                    (click)="showIndicatorsFilterDialog()"
                    [outlined]="true"
                    icon="pi pi-filter"
                    label="Filtro de indicadores"
                ></button>

                <!-- Si se han filtrado items, se muestra botón -->
                @if(filteredModel.length > 0){
                    <button
                    pButton
                    (click)="resetFilter()"
                    [outlined]="true"
                    severity="danger"
                    icon="pi pi-filter"
                    label="Limpiar filtros"
                    ></button>
                } 
            }
        </div>

        <ion-col
            size-xs="12"
            size-sm="12"
            size-md="12"
            class="margin-col-start margin-col-end"
        >
            <p-card header="Resumen indicadores">
                @if(loading){
                    <div class="spinner-container-indicator">
                        <ion-spinner name="dots"></ion-spinner>
                        <ion-label>Cargando datos...</ion-label>
                    </div>
                }

                @if(!loading){
                    <!-- Cuadrante de datos -->
                    <div class="quadrant-container">
                        <div class="first-row">
                            <div class="quadrant">
                                <p class="number">{{ totalIndicators }}</p>
                                <p class="label">Total de indicadores</p>
                            </div>
                        </div>

                        <label class="title-container">
                            <strong>Indicadores validados</strong>
                        </label>
                        <div class="second-row">
                            <div class="quadrant">
                                <p class="number">{{ totalAudited }}/{{ totalIndicators }}</p>
                                <p class="label">Indicadores auditados</p>
                            </div>
                        </div>

                        <label class="title-container">
                            <strong>Tipos</strong>
                        </label>
                        <div class="row">
                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalQualitatives }}/{{ totalIndicators }}</p>
                                    <p class="label">Narrativos</p>
                                </div>
                                <div class="text-center">
                                    Los datos vienen definidos por la creación de documentos, los cuáles pueden ser validados por usuarios
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalQuantitatives }}/{{ totalIndicators }}</p>
                                    <p class="label">Cuantitativos</p>
                                </div>
                                <div class="text-center">
                                    Se definirán datos cuantificables en forma de estado inicial, objetivos y evidencias
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoType }}/{{ totalIndicators }}</p>
                                    <p class="label">Sin tipificar</p>
                                </div>
                                <div class="text-center">
                                    Indicadores que aún no se ha definido ninguno de los tipos anteriores
                                </div>
                            </div>
                        </div>

                        <label class="title-container">
                            <strong>Estados</strong>
                        </label>
                        <div class="row">
                            <div>            
                                <div class="quadrant">
                                    <p class="number">{{ totalValidated }}/{{ totalIndicators }}</p>
                                    <p class="label">Validados</p>
                                </div>
                                <div class="text-center">
                                    Indicadores cuantitativos que tengan de su evidencia más reciente la 
                                    última evaluación marcada como <strong>correcta</strong> o para un indicador narrativo, su último documento haya sido validado. 
                                    Cumpliendo las condiciones anteriores, también se incluirán los indicadores marcados como voluntarios
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoValidated }}/{{ totalIndicators }}</p>
                                    <p class="label">No validados</p>
                                </div>
                                <div class="text-center">
                                    Indicadores cuantitativos que tengan de su evidencia más reciente la 
                                    última evaluación marcada como <strong>no correcta</strong> o para un indicador narrativo, su último documento no haya sido validado. 
                                    Cumpliendo las condiciones anteriores, también se incluirán los indicadores marcados como voluntarios
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoAppliesState }}/{{ totalIndicators }}</p>
                                    <p class="label">No aplican</p>
                                </div>
                                <div class="text-center">
                                    Con respecto al análisis de aplicabilidad más reciente, que éste haya sido marcado como 
                                    <strong>no aplica</strong> y <strong>no trata</strong>
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoType }}/{{ totalIndicators }}</p>
                                    <p class="label">Sin tipificar</p>
                                </div>
                                <div class="text-center">
                                    Indicadores que aún no se ha definido ninguno de los tipos
                                </div>
                            </div>
                        </div>

                        <label class="title-container">
                            <strong>Análisis de aplicabilidad</strong>
                        </label>
                        <div class="row">
                            <div>            
                                <div class="quadrant">
                                    <p class="number">{{ totalAppliesGap }}/{{ totalIndicators }}</p>
                                    <p class="label">Aplican</p>
                                </div>
                                <div class="text-center">
                                    Con respecto al análisis de aplicabilidad más reciente, que éste haya sido marcado como 
                                    <strong>aplica</strong> y <strong>trata</strong>
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalAppliesNoTreat }}/{{ totalIndicators }}</p>
                                    <p class="label">Aplican y no tratan</p>
                                </div>
                                <div class="text-center">
                                    Con respecto al análisis de aplicabilidad más reciente, que éste haya sido marcado como 
                                    <strong>aplica</strong> y <strong>no trata</strong>
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoAppliesGap }}/{{ totalIndicators }}</p>
                                    <p class="label">No aplican</p>
                                </div>
                                <div class="text-center">
                                    Con respecto al análisis de aplicabilidad más reciente, que éste haya sido marcado como 
                                    <strong>no aplica</strong> y <strong>no trata</strong>
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalVolunteers }}/{{ totalIndicators }}</p>
                                    <p class="label">Voluntarios</p>
                                </div>
                                <div class="text-center">
                                    Con respecto al análisis de aplicabilidad más reciente, que éste haya sido marcado como 
                                    <strong>no aplica</strong> y <strong>trata</strong>
                                </div>
                            </div>

                            <div>
                                <div class="quadrant">
                                    <p class="number">{{ totalNoAnalyzed }}/{{ totalIndicators }}</p>
                                    <p class="label">Sin analizar</p>
                                </div>
                                <div class="text-center">
                                    Indicadores que no cuentan aún con un análisis de aplicabilidad
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Primera fila de gráficas -->
                    <ion-row class="chart-container">
                        <ion-col class="col-box-bar" size-xs="12" size-sm="12" size-md="12">
                            <p-chart
                                type="bar"
                                [data]="chartBarData"
                                [options]="chartBarOptions"
                            />
                        </ion-col>
                    </ion-row>

                    <!-- Segunda fila de gráficas -->
                    <ion-row class="chart-container">
                        <ion-col class="col-box-pie" size-xs="12" size-sm="12" size-md="12">
                            <p-chart
                                type="bar"
                                [data]="dataGraphicBar2"
                                [options]="chartBar2Options"
                            ></p-chart>
                        </ion-col>

                        <ion-col size-xs="12" size-sm="12" size-md="12">
                            <table>
                                <thead>
                                    <tr>
                                    <th></th>
                                    <th>Total</th>
                                    <th *ngFor="let state of getUniqueStates()">
                                        Estado: {{ mapStateLabel(state) }}
                                    </th>
                                    <th *ngFor="let state of getUniqueStates()">
                                        Porcentaje: {{ mapStateLabel(state) }}
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of dataTable">
                                        <td>{{ row?.parentLabel }}</td>
                                        <td>{{ row?.total }}</td>
                                        <td *ngFor="let state of getUniqueStates()">
                                            {{ row[state] || 0 }}
                                        </td>
                                        <td
                                            *ngFor="let state of getUniqueStates()"
                                            [ngStyle]="{
                                            'background-color': getColorForPercentage(row.statePercentages[state] || 0).backgroundColor,
                                            'color': getColorForPercentage(row.statePercentages[state] || 0).textColor
                                            }"
                                        >
                                            {{ row.statePercentages[state] || '0.00' }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ion-col>
                    </ion-row>

                    <!-- Filtros aplicados -->
                    @if(selectedGapFilter || selectedStateFilter || selectedTypeFilter){
                        <div class="filter-container">
                            <div class="filter-title">
                                <label>
                                    <strong>Filtros aplicados</strong>
                                </label>
                            </div>

                            <div class="filter-list">
                                @if(selectedTypeFilter){
                                    <p-chip
                                        class="custom-chip-primary"
                                        label="Tipo: {{ mapStateLabel(selectedTypeFilter) }}"
                                        slot="end"
                                    ></p-chip>
                                } 
                            
                                @if(selectedStateFilter){
                                    <p-chip
                                        class="custom-chip-primary"
                                        label="Estado: {{ mapStateLabel(selectedStateFilter) }}"
                                        slot="end"
                                    ></p-chip>
                                } 
                                
                                @if(selectedGapFilter){
                                    <p-chip
                                        class="custom-chip-primary"
                                        label="Aplicabilidad: {{ mapStateLabel(selectedGapFilter) }}}"
                                        slot="end"
                                    ></p-chip>
                                }
                            </div>
                        </div>
                    }
                }

                <!--inicio bucle primer nivel-->
                <p-accordion
                    *ngFor="let parentKey of filteredModel.length > 0 ? filteredModel : model; let i = index"
                >
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <i class=" {{ parentKey.icon }}"></i>
                            <span class="span-accordion-indicator ml-2">
                                {{ parentKey.label }}
                            </span>

                            @if(user.id === 1 || (user.id !== 1 &&
                            permissionService.checkSectionPermission(userByCompany, 17, 1))){
                                <p-button
                                    pTooltip="Generar informe word de indicadores de este nivel"
                                    icon="pi pi-file-word"
                                    [loading]="loadingGenerateDocument"
                                    (click)="downloadGrandchildrenWords(parentKey); $event.stopPropagation();"
                                ></p-button>
                                <p-button
                                    icon="pi pi-download"
                                    [loading]="loadingGenerateXbrl"
                                    pTooltip="Descargar documento XBRL"
                                    (click)="downloadGrandchildrenXBRL(parentKey); $event.stopPropagation();"
                                ></p-button>
                            }

                            <div class="totales">
                                <!-- Total de indicadores -->
                                <span>
                                    <i class="pi pi-bars"></i>
                                    <span
                                        class="allindicators"
                                        pTooltip="Total"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.total }}
                                    </span>
                                </span>

                                <!-- Auditados -->
                                <span>
                                    <i class="pi pi-check-circle"></i>
                                    <span
                                        class="auditedChip"
                                        pTooltip="Auditados"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.audited }}
                                    </span>
                                </span>

                                <!-- Grupo de tipos -->
                                <span>
                                    <i class="pi pi-check"></i>
                                    <span
                                        class="typeChip"
                                        pTooltip="Narrativos"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.cualitativos }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-check"></i>
                                    <span
                                        class="typeChip"
                                        pTooltip="Cuantitativos"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.cuantitativos }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-check"></i>
                                    <span
                                        class="typeChip"
                                        pTooltip="Sin tipo asignado"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noType }}
                                    </span>
                                </span>

                                <!-- Grupo de estados -->
                                <span>
                                    <i class="pi pi-database"></i>
                                    <span
                                        class="stateChip"
                                        pTooltip="No aplica - estado"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noApplicatedState }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-database"></i>
                                    <span
                                        class="stateChip"
                                        pTooltip="Validados"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.validated }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-database"></i>
                                    <span
                                        class="stateChip"
                                        pTooltip="No validados"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noValidated }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-database"></i>
                                    <span
                                        class="stateChip"
                                        pTooltip="Sin tipificar"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noType }}
                                    </span>
                                </span>

                                <!-- Grupo GAP -->
                                <span>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="gapChip"
                                        pTooltip="Aplica"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.appliesGap }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="gapChip"
                                        pTooltip="Aplica y no trata"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.appliesNoTreat }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="gapChip"
                                        pTooltip="No aplica"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noAppliesGap }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="gapChip"
                                        pTooltip="Voluntarios"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.volunteers }}
                                    </span>
                                </span>

                                <span>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="gapChip"
                                        pTooltip="No analizados"
                                        tooltipPosition="top"
                                    >
                                        {{ parentKey.totales.noAnalyzed }}
                                    </span>
                                </span>
                            </div>
                        </ng-template>

                        <!--inicio bucle segundo nivel-->
                        <p-accordion *ngFor="let childKey of parentKey['items']">
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <i class=" {{ childKey.icon }}"></i>
                                    <span class="span-accordion-indicator ml-2">
                                        {{ childKey.label }}
                                    </span>

                                    @if(user.id === 1 || (user.id !== 1 &&
                                    permissionService.checkSectionPermission(userByCompany, 17, 1))){
                                        <p-button
                                            pTooltip="Generar informe word de indicadores de este nivel"
                                            [loading]="loadingGenerateDocument"
                                            icon="pi pi-file-word"
                                            (click)="downloadGrandchildrenWords(childKey); $event.stopPropagation();"
                                        ></p-button>
                                    }

                                    <div class="totales">
                                        <!-- Total -->
                                        <span>
                                            <i class="pi pi-bars"></i>
                                            <span
                                                class="allindicators"
                                                pTooltip="Total"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.total }}
                                            </span>
                                        </span>

                                        <!-- Auditados -->
                                        <span>
                                            <i class="pi pi-check-circle"></i>
                                            <span
                                                class="auditedChip"
                                                pTooltip="Auditados"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.audited }}
                                            </span>
                                        </span>

                                        <!-- Grupo de tipos -->
                                        <span>
                                            <i class="pi pi-check"></i>
                                            <span
                                                class="typeChip"
                                                pTooltip="Narrativos"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.cualitativos }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-check"></i>
                                            <span
                                                class="typeChip"
                                                pTooltip="Cuantitativos"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.cuantitativos }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-check"></i>
                                            <span
                                                class="typeChip"
                                                pTooltip="Sin tipo asignado"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noType }}
                                            </span>
                                        </span>

                                        <!-- Grupo de estados -->
                                        <span>
                                            <i class="pi pi-database"></i>
                                            <span
                                                class="stateChip"
                                                pTooltip="No aplica - estado"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noApplicatedState }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-database"></i>
                                            <span
                                                class="stateChip"
                                                pTooltip="Validado"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.validated }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-database"></i>
                                            <span
                                                class="stateChip"
                                                pTooltip="No validado"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noValidated }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-database"></i>
                                            <span
                                                class="stateChip"
                                                pTooltip="Sin tipificar"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noType }}
                                            </span>
                                        </span>

                                        <!-- Grupo GAP -->
                                        <span>
                                            <i class="pi pi-file"></i>
                                            <span
                                                class="gapChip"
                                                pTooltip="Aplica"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.appliesGap }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-file"></i>
                                            <span
                                                class="gapChip"
                                                pTooltip="Aplica y no trata"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.appliesNoTreat }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-file"></i>
                                            <span
                                                class="gapChip"
                                                pTooltip="No aplica - aplicabilidad"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noAppliesGap }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-file"></i>
                                            <span
                                                class="gapChip"
                                                pTooltip="Voluntarios"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.volunteers }}
                                            </span>
                                        </span>

                                        <span>
                                            <i class="pi pi-file"></i>
                                            <span
                                                class="gapChip"
                                                pTooltip="No analizados"
                                                tooltipPosition="top"
                                            >
                                                {{ childKey.totales.noAnalyzed }}
                                            </span>
                                        </span>
                                    </div>
                                </ng-template>

                                <!--inicio bucle tercer nivel-->
                                <p-accordion *ngFor="let grandchild of childKey['items']">
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <i class=" {{ grandchild.icon }}"></i>
                                            <span class="span-accordion-indicator ml-2">
                                                {{ grandchild.label }}
                                            </span>

                                            @if(user && user.id === 1 || (user.id !== 1 &&
                                            permissionService.checkSectionPermission(userByCompany,
                                            17, 1))){
                                                <p-button
                                                    pTooltip="Generar informe word de indicadores de este nivel"
                                                    [loading]="loadingGenerateDocument"
                                                    icon="pi pi-file-word"
                                                    (click)="downloadGrandchildrenWords(grandchild); $event.stopPropagation();"
                                                ></p-button>
                                            }

                                            <div class="totales">
                                                <!-- Total -->
                                                <span>
                                                    <i class="pi pi-bars"></i>
                                                    <span
                                                        class="allindicators"
                                                        pTooltip="Total"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.total }}
                                                    </span>
                                                </span>

                                                <!-- Auditados -->
                                                <span>
                                                    <i class="pi pi-check-circle"></i>
                                                    <span
                                                        class="auditedChip"
                                                        pTooltip="Auditados"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.audited }}
                                                    </span>
                                                </span>
                                
                                                <!-- Grupo de tipos -->
                                                <span>
                                                    <i class="pi pi-check"></i>
                                                    <span
                                                        class="typeChip"
                                                        pTooltip="Narrativos"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.cualitativos }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-check"></i>
                                                    <span
                                                        class="typeChip"
                                                        pTooltip="Cuantitativos"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.cuantitativos }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-check"></i>
                                                    <span
                                                        class="typeChip"
                                                        pTooltip="Sin tipo asignado"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noType }}
                                                    </span>
                                                </span>

                                                <!-- Grupo de estados -->
                                                <span>
                                                    <i class="pi pi-database"></i>
                                                    <span
                                                        class="stateChip"
                                                        pTooltip="No aplica"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noApplicatedState }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-database"></i>
                                                    <span
                                                        class="stateChip"
                                                        pTooltip="Validados"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.validated }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-database"></i>
                                                    <span
                                                        class="stateChip"
                                                        pTooltip="No validados"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noValidated }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-database"></i>
                                                    <span
                                                        class="stateChip"
                                                        pTooltip="Sin tipificar"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noType }}
                                                    </span>
                                                </span>

                                                <!-- Grupo GAP -->
                                                <span>
                                                    <i class="pi pi-file"></i>
                                                    <span
                                                        class="gapChip"
                                                        pTooltip="Aplica"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.appliesGap }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-file"></i>
                                                    <span
                                                        class="gapChip"
                                                        pTooltip="Aplica y no trata"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.appliesNoTreat }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-file"></i>
                                                    <span
                                                        class="gapChip"
                                                        pTooltip="No aplica - aplicabilidad"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noAppliesGap }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-file"></i>
                                                    <span
                                                        class="gapChip"
                                                        pTooltip="Voluntarios"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.volunteers }}
                                                    </span>
                                                </span>

                                                <span>
                                                    <i class="pi pi-file"></i>
                                                    <span
                                                        class="gapChip"
                                                        pTooltip="No analizados"
                                                        tooltipPosition="top"
                                                    >
                                                        {{ grandchild.totales.noAnalyzed }}
                                                    </span>
                                                </span>
                                            </div>
                                        </ng-template>

                                        <!--inicio bucle indicadores-->
                                        @for(indicator of grandchild.data.indicators; track indicator){
                                            <ion-item class="item-indicator" lines="none">
                                                @if(indicator.type === 'qualitative' || indicator.type === 'quantitative'){
                                                <!-- indicador con tipo -->
                                                <i
                                                    class="pi pi-sign-in click"
                                                    (click)="redirect(indicator)"
                                                >
                                                    <span
                                                    class="click"
                                                    style="
                                                        font-weight: 400 !important;
                                                        font-family: 'Inter var', sans-serif !important;
                                                    "
                                                    >
                                                    {{ indicator.name }}
                                                    </span>
                                                </i>
                                                } @else {
                                                <!-- indicador sin tipo -->
                                                <div
                                                    pTooltip="Debe definir el tipo de indicador antes de acceder a él"
                                                >
                                                    <i class="pi pi-sign-in click">
                                                    <span
                                                        class="click"
                                                        style="
                                                        font-weight: 400 !important;
                                                        font-family: 'Inter var', sans-serif !important;
                                                        "
                                                    >
                                                        {{ indicator.name }}
                                                    </span>
                                                    </i>
                                                </div>
                                                } 
                                            
                                                @if(hasBothStandards(indicator, 1, 2)){
                                                    <ion-avatar slot="end">
                                                        <img src="assets/images/gri.png" alt="" />
                                                    </ion-avatar>
                                                    <ion-avatar slot="end">
                                                        <img src="assets/images/esrs.png" alt="" />
                                                    </ion-avatar>
                                                } @else if(hasStandard(indicator, 1)){
                                                    <ion-avatar slot="end">
                                                        <img src="assets/images/esrs.png" alt="" />
                                                    </ion-avatar>
                                                } @else if (hasStandard(indicator, 2)){
                                                    <ion-avatar slot="end">
                                                        <img src="assets/images/gri.png" alt="" />
                                                    </ion-avatar>
                                                }
                                                

                                                <!-- Botón para indicar estado del indicador -->
                                                @if(indicator.type === 'gap' || indicator.type ===
                                                'noType'){ @if(indicator.gapState === 'APPLIES_GAP'){
                                                    <p-chip
                                                        class="custom-chip-success"
                                                        icon="pi pi-check"
                                                        label="Aplica"
                                                        slot="end"
                                                        pTooltip="Sin tipificar"
                                                    ></p-chip>
                                                } 
                                            
                                                @if(indicator.gapState === 'VOLUNTEER'){
                                                    <p-chip
                                                        class="custom-chip-danger"
                                                        icon="pi pi-check"
                                                        label="Voluntario"
                                                        slot="end"
                                                        pTooltip="Sin tipificar"
                                                    ></p-chip>
                                                } 
                                            
                                                @if(indicator.gapState === 'APPLIES_NO_TREATED'){
                                                    <p-chip
                                                        class="custom-chip-warning"
                                                        icon="pi pi-check"
                                                        label="Aplica y no trata"
                                                        slot="end"
                                                        pTooltip="Sin tipificar"
                                                    ></p-chip>
                                                } 
                                                
                                                @if(indicator.gapState === 'NO_APPLIES_GAP'){
                                                    <p-chip
                                                        class="custom-chip-warning"
                                                        icon="pi pi-check"
                                                        label="No aplica"
                                                        slot="end"
                                                        pTooltip="Sin tipificar"
                                                    ></p-chip>
                                                } 
                                            
                                                @if(indicator.gapState === 'NO_ANALYZED'){
                                                    <p-chip
                                                        class="custom-chip-danger"
                                                        icon="pi pi-check"
                                                        label="Sin analizar"
                                                        slot="end"
                                                        pTooltip="Sin tipificar"
                                                    ></p-chip>
                                                } 
                                            } 
                                        
                                            @if(indicator.type === 'qualitative'){
                                                @if(indicator.state === 'VALIDATED'){
                                                    <p-chip
                                                        class="custom-chip-success"
                                                        icon="pi pi-check"
                                                        label="Validado"
                                                        slot="end"
                                                        pTooltip="Narrativo"
                                                    ></p-chip>
                                                } 
                                            
                                                @if(indicator.state === 'NO_VALIDATED'){
                                                    <p-chip
                                                        class="custom-chip-danger"
                                                        icon="pi pi-check"
                                                        label="No validado"
                                                        slot="end"
                                                        pTooltip="Narrativo"
                                                    ></p-chip>
                                                } 
                                            
                                                @if(indicator.state === 'NO_APPLIES_STATE'){
                                                    <p-chip
                                                        class="custom-chip-warning"
                                                        icon="pi pi-check"
                                                        label="No aplica"
                                                        slot="end"
                                                        pTooltip="Narrativo"
                                                    ></p-chip>
                                                } 
                                            } 
                                        
                                            @if(indicator.type === 'quantitative'){
                                                @if(indicator.state === 'VALIDATED'){
                                                    <div
                                                        class="traffic-light"
                                                        slot="end"
                                                        pTooltip="Cuantitativo"
                                                    >
                                                        <div class="light grey"></div>
                                                        <div class="light grey"></div>
                                                        <div class="light green"></div>
                                                    </div>
                                                } 
                                            
                                                @if(indicator.state === 'NO_VALIDATED'){
                                                    <div
                                                        class="traffic-light"
                                                        slot="end"
                                                        pTooltip="Cuantitativo"
                                                    >
                                                        <div class="light red"></div>
                                                        <div class="light grey"></div>
                                                        <div class="light grey"></div>
                                                    </div>
                                                } 
                                            
                                                @if(indicator.state === 'NO_APPLIES_STATE'){
                                                    <div
                                                        class="traffic-light"
                                                        slot="end"
                                                        pTooltip="Cuantitativo"
                                                    >
                                                        <div class="light grey"></div>
                                                        <div class="light yellow"></div>
                                                        <div class="light grey"></div>
                                                    </div>
                                                } 
                                            }
                                            </ion-item>
                                        }
                                    </p-accordionTab>
                                </p-accordion>
                            </p-accordionTab>
                        </p-accordion>
                    </p-accordionTab>
                </p-accordion>
            </p-card>
        </ion-col>
    </ion-row>
</ion-grid>

<!-- Modal para filtro de evidencias -->
<p-dialog
  header="Filtro de evidencias"
  [(visible)]="dialogFilter"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
  (onHide)="rangeDates = []"
>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Opciones de descarga de informe</label>
          <div class="mt-1">
            <p-dropdown
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción de descarga"
              [options]="wordFilterOptions"
              [(ngModel)]="selectedFilter"
            />
          </div>
        </ion-col>
        <!-- Por rango de fechas -->
        @if(selectedFilter === 2){
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Rango de fechas</label>
          <div class="mt-1">
            <p-calendar
              class="calendar-form"
              appendTo="body"
              [(ngModel)]="rangeDates"
              selectionMode="range"
              dateFormat="dd/mm/yy"
              [readonlyInput]="true"
            />
          </div>
        </ion-col>
        }
      </ion-row>
    </ion-grid>
  </div>

  <ng-template pTemplate="footer">
    @if(selectedFilter === 2 && rangeDates.length > 0){
    <p-button
      label="Limpiar filtro"
      severity="danger"
      (click)="rangeDates = []"
    >
    </p-button>
    }
    <p-button
      label="Descargar"
      [loading]="loadingWord"
      (click)="onClickDownloadWord()"
    >
    </p-button>
    <p-button
      label="Descargar todos"
      disabled
      [loading]="loadingWord"
      (click)="downloadIndicatorsWord()"
    >
    </p-button>
    <p-button
      label="Descargar estándar GRI"
      disabled
      [loading]="loadingWord"
      (click)="downloadIndicatorsWord()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para filtro de indicadores -->
<p-dialog
  header="Filtro de indicadores"
  [(visible)]="dialogIndicatorFilter"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw' }"
>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Estado del indicador</label>
          <div class="mt-1">
            <p-dropdown
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione estado del indicador"
              [options]="indicatorState"
              [(ngModel)]="selectedStateFilter"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Estado del indicador según su aplicabilidad</label>
          <div class="mt-1">
            <p-dropdown
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione estado de aplicabilidad"
              [options]="indicatorGap"
              [(ngModel)]="selectedGapFilter"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Tipo del indicador</label>
          <div class="mt-1">
            <p-dropdown
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione tipo del indicador"
              [options]="indicatorType"
              [(ngModel)]="selectedTypeFilter"
            />
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  
  <ng-template pTemplate="footer">
    <p-button label="Aplicar filtro" (click)="onSubmitIndicadorFilter()">
    </p-button>
  </ng-template>
</p-dialog>
