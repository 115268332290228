<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
<div class="spinner-container-indicator">
  <ion-spinner name="dots"> </ion-spinner>
  <ion-label>Cargando proveedores...</ion-label>
</div>
}
<!--  -->
@if(!loading){
<ion-grid class="p-4">
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <h2>Proveedores</h2>
    </ion-col>
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <p-table
        #dt1
        styleClass="p-datatable-striped"
        responsiveLayout="scroll"
        [value]="users"
        [rowHover]="true"
        [paginator]="true"
        [rows]="10"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="caption">
          <div
            style="gap: 1vw"
            class="flex justify-content-center align-items-center sm:flex-row"
          >
            <input
              pInputText
              type="text"
              #filterGeneral
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Buscar proveedor"
              class="w-full"
            />
            <p-button
              (click)="showUserDialog()"
              icon="pi pi-plus"
              label="Nuevo proveedor"
            >
            </p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Opciones</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td class="text-center">{{ user.user.name }}</td>
            <td class="text-center">{{ user.user.email }}</td>
            <td>
              <div class="flex justify-content-center align-items-center">
                <p-dropdown              
                  [options]="crudOptionsProducts"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  [(ngModel)]="selectedCrudOption"
                  (onChange)="handleDropdown($event, user)"
                ></p-dropdown>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No se encontraron proveedores</td>
          </tr>
        </ng-template>
      </p-table>
    </ion-col>
  </ion-row>
</ion-grid>
}

<!-- Modal para crear/editar datos básicos -->
<p-dialog
  header="Formulario de proveedor"
  [(visible)]="dialogUser"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeUserModal()"
>
  <div>
    <div class="container-img">
      <div class="custom-file-upload" *ngIf="mainStringPhoto">
        <img [src]="mainStringPhoto" style="width: 15vw" alt="Foto cargada" />
        <button
          class="ml-2"
          type="button"
          pButton
          icon="pi pi-trash"
          (click)="deletePhoto()"
        ></button>
      </div>
      <div class="custom-file-upload">
        <input
          id="photoInput2"
          type="file"
          (change)="handleUploadPhoto($event)"
          accept=".jpg,.png"
        />
        <label class="btn-img">Selecciona imagen de perfil</label>
      </div>
    </div>

    <form [formGroup]="formUser">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">*Nombre completo</label>
            <input
              type="text"
              id="name"
              pInputText
              formControlName="name"
              class="w-full mt-1"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="formUser.get('name')!.errors && formUser.get('name')!.touched && formUser.get('name')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="email">*Email</label>
            <input
              pInputText
              id="email"
              formControlName="email"
              class="w-full mt-1"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="formUser.get('email')!.errors && formUser.get('email')!.touched && formUser.get('email')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <!-- Editar contraseña -->
          @if(edit || !boolAzure){
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Contraseña</label>
            <div class="mt-1">
              <p-password
                appendTo="body"
                formControlName="password"
                [feedback]="true"
                [toggleMask]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
              >
              </p-password>
              <span
                class="flex justify-content-center text-center m-2"
                style="color: red; font-size: 12px"
                *ngIf="formUser.get('password')!.errors && formUser.get('password')!.touched && formUser.get('password')!.errors?.['minlength']"
                >Mínimo 8 caracteres</span
              >
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Confirmación de contraseña</label>
            <div class="mt-1">
              <p-password
                appendTo="body"
                formControlName="repeat_password"
                [feedback]="false"
                [toggleMask]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
              >
              </p-password>
            </div>
          </ion-col>
          }
          <!--  -->
          @if (boolAzure && !edit) {
          <div
            class="w-full h-full flex align-items-center justify-content-center p-4"
          >
            <span
              >Se usará contraseña de cuenta de Microsoft asociada al email que
              se defina en este formulario</span
            >
          </div>
          }
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmitUser()">
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para editar datos específicos de usuario -->
<p-dialog
  header="Formulario específico de proveedor"
  [(visible)]="dialogDetail"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeDetailModal()"
>
  <form [formGroup]="formDetail">
    @if(companySelected){
      <ion-grid>
        <ion-row>
          <!-- Inputs disabled para razón social y CIF -->
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Razón social</label>
            <div class="mt-1">
              <input
                [disabled]="true"
                [(ngModel)]="companySelected.name"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                class="mt-1 w-full"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">CIF</label>
            <div class="mt-1">
              <input
                [disabled]="true"
                [(ngModel)]="companySelected.cif"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                class="mt-1 w-full"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Responsable</label>
            <div class="mt-1">
              <input
                pInputText
                formControlName="responsible"
                class="mt-1 w-full"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Fecha de entrada</label>
            <div class="mt-1">
              <p-calendar
                appendTo="body"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                class="calendar-form"
                formControlName="entry_date"
                dateFormat="dd/mm/yy"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Dirección</label>
            <input pInputText formControlName="address" class="mt-1 w-full" />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Código postal</label>
            <input
              pInputText
              maxlength="5"
              formControlName="zip_code"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Ciudad</label>
            <div class="mt-1">
              <input pInputText formControlName="city" class="mt-1 w-full" />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Provincia</label>
            <div class="mt-1">
              <input pInputText formControlName="province" class="mt-1 w-full" />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>País</label>
            <div class="mt-1">
              <input pInputText formControlName="country" class="mt-1 w-full" />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Criticalidad</label>
            <div class="mt-1">
              <p-inputNumber
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                class="mt-1 w-full"
                inputId="integeronly"
                formControlName="criticality"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="12">
            <label>Documento</label>
            <div class="mt-1">
              <p-fileUpload
                name="file"
                chooseLabel="Seleccionar archivo"
                [customUpload]="true"
                [showUploadButton]="false"
                (onSelect)="onFileSelect($event)"
                (onRemove)="deleteFileSelected($event)"
                accept=".pdf"
              ></p-fileUpload>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    }
  </form>

  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="onSubmitDetail()"
    ></p-button>
  </ng-template>
</p-dialog>

