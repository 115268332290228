<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>
  <ion-row style="margin-top: -6rem !important">
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
    <p-card>
      @if(loading){
      <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
        <div class="spinner-container-indicator">
          <ion-spinner name="dots"></ion-spinner>
          <ion-label>Cargando datos, por favor espere...</ion-label>
        </div>
      </ion-col>
      } @else {
        <div slot="header" class="header-with-buttons">
          <h3>Resumen indicadores: doble materialidad</h3>
          <p-button
            label="Gestionar grupos"
            (click)="handleAddButton($event)"
          ></p-button>
        </div>

        <div class="w-full mb-1">
          @if(groupsWithDmData.length > 0){
            <p-dataView #dv [value]="groupsWithDmData" [rows]="5" [paginator]="true">
              <ng-template pTemplate="list" let-groupsWithDmData>
                <ng-container
                  *ngFor="let group of groupsWithDmData; trackBy: trackById"
                >
                  <p-accordion class="accordion-indicator">
                    <p-accordionTab [header]="group.name">
                      <div
                        class="w-full flex justify-content-center align-items-center mb-4 gap-3"
                      >
                        <p-button
                          label="Mostrar matriz de riesgos"
                          icon="pi pi-eye"
                          (click)="openRiskMatrixDialog(group)"
                        ></p-button>
                        <p-button
                          [loading]="loadingReport"
                          pTooltip="Descargar informe"
                          icon="pi pi-download"
                          (click)="showReportDocumentConfirmation(group)"
                        ></p-button>
                      </div>

                      <p-table
                        #dt1
                        styleClass="p-datatable-striped"
                        responsiveLayout="scroll"
                        [value]="group['dualMat']"
                        [rowHover]="true"
                        [paginator]="true"
                        [rows]="5"
                        [globalFilterFields]="['name', 'parentLevel']"
                      >
                        <ng-template pTemplate="caption">
                          <div
                            style="gap: 1vw"
                            class="flex justify-content-center align-items-center sm:flex-row"
                          >
                            <input
                              pInputText
                              type="text"
                              #filterGeneral
                              (input)="dt1.filterGlobal($event.target.value, 'contains')"
                              placeholder="Buscar registro"
                              class="w-full"
                            />
                            <p-button
                              icon="pi pi-plus"
                              pTooltip="Nuevo registro de doble materialidad"
                              (click)="showDualMaterialityFormDialog(group)"
                            ></p-button>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                          <tr>
                            <th>Nombre identificativo</th>
                            <th>Indicadores</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de actualización</th>
                            <th>Opciones</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-dm>
                          <tr>
                            <td>{{ dm.name }}</td>
                            <td>
                              <p-button
                                label="Ver niveles e indicadores"
                                (click)="showIndicatorsByDmLevel(dm)"
                              ></p-button>
                            </td>
                            <td>{{ dm.created_at ? (dm.created_at | date: 'dd/MM/YY HH:mm') : 'Sin fecha' }}</td>
                            <td>{{ dm.updated_at ? (dm.updated_at | date: 'dd/MM/YY HH:mm') : 'Sin fecha' }}</td>
                            <td style="width: 100px;">
                              <div class="btn-options">
                                <p-button
                                  [loading]="loadingModal"
                                  pTooltip="Editar registro"
                                  icon="pi pi-pencil"
                                  (click)="showUpdateModal(group, dm)"
                                ></p-button>
                                <p-button
                                  severity="danger"
                                  pTooltip="Eliminar registro"
                                  icon="pi pi-trash"
                                  (click)="deleteDm(dm)"
                                ></p-button>
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="7">No se encontraron registros</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-accordionTab>
                  </p-accordion>
                </ng-container>
              </ng-template>
            </p-dataView>
          } @else {
            <div
              class="p-4 w-full flex align-items-center justify-content-center text-center"
            >
              No existen grupos de doble materialidad
            </div>
          }
        </div>
      }
    </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para cumplimentar doble materialidad -->
<p-dialog
  header="Doble materialidad"
  [(visible)]="dialogDualMaterialityForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '95vw', height: '100vh'}"
  (onHide)="handleHideDualMateriality()"
>
  <p-stepper [(activeStep)]="stepperIndex">
    <!-- Paso 1 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <label for="">*Nombre identificativo para el registro de doble materialidad</label>
              <div class="mt-1">
                <input
                  placeholder="Introduce un nombre para el registro de doble materialidad"
                  [(ngModel)]="dmName"
                  [ngModelOptions]="{ standalone: true }"
                  class="w-full"
                  pInputText
                />
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="flex pt-4 justify-content-end">
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 2 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback" 
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 2 -->
        <form [formGroup]="form1">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Comprensión del contexto: </b> este paso inicial implica un
                  análisis profundo del entorno en el que opera la empresa
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Regulaciones</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    rows="10"
                    pInputTextarea
                    formControlName="regulations_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 1)"
                    (onSelect)="onFileSelect($event, 1, 1)"
                    (onRemove)="deleteFileSelected($event,1, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[1] && existingFiles[1][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[1][1])"
                    ></p-button>
                  </div>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">
                    Actividades y relaciones comerciales
                    <i
                      class="pi pi-question-circle click-pointer"
                      (click)="op1.toggle($event)"
                    ></i>
                  </label>
                  <!-- Overlay -->
                  <p-overlayPanel #op1>
                    <div style="width: 40vw" class="flex flex-column">
                      <p>
                        Las actividades y relaciones comerciales relacionadas
                        con la <strong>NEIS 2 SBM-1</strong> se plantean en
                        términos de:
                      </p>
                      <ol>
                        <li>
                          El análisis del plan de negocio de la empresa, su
                          estrategia, sus estados financieros y, en su caso,
                          cualquier otra información facilitada a los
                          inversores;
                        </li>
                        <li>
                          Las actividades de la empresa, sus productos/servicios
                          y la localización geográfica de dichas actividades; y
                        </li>
                        <li>
                          El mapeo de las relaciones comerciales de la empresa y
                          aguas arriba y aguas abajo de la cadena de valor,
                          incluido el tipo y la naturaleza de las relaciones
                          comerciales.
                        </li>
                      </ol>
                    </div>
                  </p-overlayPanel>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="10"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    formControlName="business_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 2)"
                    (onSelect)="onFileSelect($event, 1, 2)"
                    (onRemove)="deleteFileSelected($event, 1, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[1] && existingFiles[1][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[1][2])"
                    ></p-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 3 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback" 
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 3 -->
        <ion-grid>
          <div class="header-container flex-column">
            <div class="w-full flex justify-content-center text-center">
              <label>
                <strong>Comprensión de las partes interesadas afectadas</strong>
                <i
                  class="pi pi-question-circle click-pointer ml-2"
                  (click)="op2.toggle($event)"
                ></i>
              </label>
            </div>

            @if(stakeholdersArray.length > 0){
              <div class="w-full flex justify-content-center mt-4">
                <p-button
                  label="Ver tabla de valores actuales"
                  (click)="showStep2Table()"
                  icon="pi pi-eye"
                ></p-button>
              </div>
            } @else {
              <div class="w-full flex justify-content-center mt-4">
                <p>No existen registros para esta parte del formulario.</p>
              </div>
            }

            <!-- Overlay -->
            <p-overlayPanel #op2>
              <div style="width: 40vw" class="flex flex-column">
                <p>
                  El objetivo consiste en comprender qué partes
                  interesadas se ven o pueden verse afectadas por las
                  operaciones propias de la empresa y aguas arriba y aguas
                  abajo de la cadena de valor. También incluye sus
                  opiniones e intereses (en consonancia con lo indicado en
                  la
                  <strong
                    >NEIS 2 SBM-2 Intereses y opiniones de las partes
                    interesadas</strong
                  >). A partir de ahí, la empresa puede identificar a las
                  principales partes interesadas. Para ayudar a esa
                  comprensión, se puede tener en cuenta lo siguiente:
                </p>
                <ul>
                  <li>
                    Un análisis de las iniciativas existentes de
                    colaboración de las partes interesadas (por ejemplo, a
                    través de los equipos de comunicación, relaciones con
                    los inversores, gestión empresarial, ventas y
                    compras); y
                  </li>
                  <li>
                    Un mapeo de las partes interesadas afectadas en todas
                    las actividades y relaciones comerciales de la
                    empresa. Se pueden identificar grupos independientes
                    de partes interesadas afectadas por actividad,
                    producto o servicio y se les debe dar prioridad con
                    respecto a una determinada cuestión de sostenibilidad.
                  </li>
                </ul>
              </div>
            </p-overlayPanel>
          </div>

          @if(edit && stakeholdersArray.length > 0){
            <div class="disclaimer-edit">
              <span>*Debe pulsar en <strong>Guardar modificación</strong> para guardar los cambios de cada registro de manera individual.</span>
            </div>
          }

          <form [formGroup]="formStakeholders">
            <div formArrayName="stakeholders">
              <ion-row *ngFor="let stakeholder of stakeholdersArray.controls; let i = index" [formGroupName]="i">
                <label class="mb-2 mt-2">
                  <strong>Registro {{ i+1 }}</strong>
                </label>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label>*Nombre de la parte interesada</label>
                  <div class="mt-1">
                    <input
                      formControlName="name"
                      class="w-full"
                      pInputText
                      (input)="updateMatrixByNameInput($event)"
                    />
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label>*Nivel de influencia</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="influence_level"
                      [options]="numberScale"
                      optionValue="value"
                      placeholder="Seleccione un valor"
                      class="w-full"
                      appendTo="body"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      (onChange)="updateTotal(i)">
                    </p-dropdown>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label>*Impacto en los riesgos</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="impact_risks"
                      optionValue="value"
                      [options]="numberScale"
                      appendTo="body"
                      placeholder="Seleccione un valor"
                      class="w-full"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      (onChange)="updateTotal(i)">
                    </p-dropdown>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label>Descripción del rol o preocupaciones clave</label>
                  <div class="mt-1 mb-3">
                    <textarea
                      pInputTextarea
                      rows="5"
                      class="w-full"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      formControlName="description"
                    ></textarea>
                  </div>
                </ion-col>

                <ion-row>
                  <ion-col size-xs="12" size-sm="12" size-md="12">
                    <div class="flex gap-2">
                      <p-button 
                        severity="danger"
                        [loading]="loadingForm"
                        label="Eliminar registro" 
                        icon="pi pi-trash" 
                        (click)="removeStakeholder($event, i, stakeholdersArray.controls[i].value)">
                      </p-button>
                      @if(stakeholdersArray.controls[i].value.id && stakeholdersArray.controls[i].value.id !== ""){
                        <p-button
                          [loading]="loadingForm"
                          label="Guardar modificación" 
                          icon="pi pi-pencil" 
                          (click)="onSubmitUpdateStakeholderRegister(i)">
                        </p-button>
                      }
                    </div>
                  </ion-col>
                </ion-row>
              </ion-row>
            </div>

            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <div class="flex align-items-center justify-content-center w-full">
                  <p-button
                    [loading]="loadingForm"
                    label="Agregar registro" 
                    icon="pi pi-plus" 
                    (click)="addStakeholder()">
                  </p-button>
                </div>
              </ion-col>
            </ion-row>
          </form>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 4 - Matriz del paso 3 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        @if(this.matrixGroupData?.matrixData !== undefined || this.matrixGroupData?.matrixData){
          <div class="table-container">
            <div class="horizontal-header-container">
              <div class="scrollable-table">
                <label>Matriz para comprensión de las partes interesadas afectadas</label>
                <table class="risk-matrix-table mt-1">
                  <thead>
                    <tr>
                      <th></th>
                      <th *ngFor="let influenceLevel of influenceLevels">
                        {{ getAxisLabel(influenceLevel) }}
                      </th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr *ngFor="let impactRisk of impactRisks; let rowIndex = index">
                      <td class="header-cell">{{ getAxisLabel(impactRisk) }}</td>
                      <td
                        *ngFor="let influenceLevel of influenceLevels; let colIndex = index"
                        [ngClass]="getCellClass(rowIndex, colIndex)"
                      >
                        <div class="chip-container">
                          <div
                            class="chip"
                            *ngFor="
                              let value of matrixGroupData?.matrixData[impactRisk]?.[influenceLevel] || [];
                              let chipIndex = index
                            "
                            [ngClass]="value?.priority?.toLowerCase()"
                          >
                            <span class="chip-label">{{ value.name + ' ' + '(' + value.total + ')'}} </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="w-full disclaimer-matrix-3 mt-1">
            <p>*Los valores de cada elemento de la matriz se obtienen del producto entre el <strong>nivel de influencia</strong> y el <strong>impacto en los riesgos</strong>.</p>
          </div>
        } @else {
          <div class="flex w-full justify-content-center">
            <p>Para visualizar la matriz de riesgos, debe añadir al menos un registro en el paso 3</p>
          </div>
        }
        
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 5 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 5 -->
        <form [formGroup]="form2">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b
                    >Identificación de los impactos, riesgos y oportunidades
                    reales y potenciales relacionados con cuestiones de
                    sostenibilidad: </b
                  >este paso implica la recopilación de datos y la consulta con
                  las partes interesadas para identificar los temas relevantes
                  <i
                    class="pi pi-question-circle click-pointer"
                    (click)="op3.toggle($event)"
                  ></i>
                </p>
              </div>
              <p-overlayPanel #op3>
                <div style="width: 40vw" class="flex flex-column">
                  <p>
                    En este paso, la empresa identifica los
                    <strong>impactos, riesgos y oportunidades (IRO)</strong>
                    reales y potenciales relacionados con cuestiones
                    medioambientales, sociales y de gobernanza en sus
                    operaciones propias y aguas arriba y aguas abajo de su
                    cadena de valor. El resultado será una «larga» lista de
                    impactos, riesgos y oportunidades para su posterior
                    evaluación y análisis en etapas posteriores.
                  </p>
                </div>
              </p-overlayPanel>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas internas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="internal_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 1)"
                    (onSelect)="onFileSelect($event, 2, 1)"
                    (onRemove)="deleteFileSelected($event, 2, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[2] && existingFiles[2][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[2][1])"
                    ></p-button>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas externas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="external_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 2)"
                    (onSelect)="onFileSelect($event, 2, 2)"
                    (onRemove)="deleteFileSelected($event, 2, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[2] && existingFiles[2][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[2][2])"
                    ></p-button>
                  </div>
                </ion-col>

                <!-- Tabla de impactos, riesgos y oportunidades -->
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <h3 class="text-left">Listado de impactos</h3>
                  <p-table
                    #dt1
                    styleClass="p-datatable-striped"
                    responsiveLayout="scroll"
                    [value]="impactRiskOportunity"
                    [rowHover]="true"
                    [paginator]="true"
                    [rows]="3"
                    [globalFilterFields]="['impact_text', 'risk_name', 'oportunity_text']"
                  >
                    <ng-template pTemplate="caption">
                      <div
                        style="gap: 1vw"
                        class="flex justify-content-center align-items-center sm:flex-row"
                      >
                        <input
                          pInputText
                          type="text"
                          #filterGeneral
                          (input)="dt1.filterGlobal($event.target.value, 'contains')"
                          placeholder="Buscar registro"
                          class="w-full"
                        />
                        <p-button
                          icon="pi pi-plus"
                          pTooltip="Nuevo registro"
                          (click)="showImpactRegisterDialog()"
                        ></p-button>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Identificador</th>
                        <th>Impactos</th>
                        <th>Riesgos</th>
                        <th>Oportunidades</th>
                        <th>Opciones</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                      <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.impact_form_impact_text }}</td>
                        <td>{{ item.impact_form_risk_text }}</td>
                        <td>{{ item.impact_form_opportunity_text }}</td>
                        <td>
                          <p-button
                            class="m-1"
                            pTooltip="Editar registro"
                            icon="pi pi-pencil"
                            (click)="showImpactRegisterDialog(item)"
                          ></p-button>
                          <p-button
                            class="m-1"
                            severity="danger"
                            pTooltip="Eliminar registro"
                            icon="pi pi-trash"
                            (click)="deleteImpact($event, item)"
                          ></p-button>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="7">No se encontraron registros</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ion-col>
                <!-- Fin tabla -->
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 6 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" class="step5-table">
              <label
                >Upstream y Downstream por análisis de materialidad del
                impacto</label
              >
              @if(impactRiskOportunity.length > 0){
              <table class="impact-table mt-2">
                <thead>
                  <tr>
                    <th rowspan="2">Ilustración<br />Impacto negativo</th>
                    <th colspan="3">Evaluación de la gravedad</th>
                    <th rowspan="2">
                      ¿Se considera que el impacto es material?
                    </th>
                  </tr>
                  <tr>
                    <th>Magnitud</th>
                    <th>Alcance</th>
                    <th>Carácter irremediable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of impactRiskOportunity; let i = index">
                    @if(item.id){
                      <td>{{ item.id }}</td>
                    } @else {
                      <td>{{ 'Nuevo impacto creado' }}</td>
                    }

                    <!-- Magnitud -->
                    <td
                      [ngClass]="getColorClass(item.upstreamDownstream.magnitude)"
                      (click)="openOverlayStep3($event, 'magnitude', item, i)"
                    >
                      {{ item.upstreamDownstream.magnitude }}
                    </td>
                    <!-- Alcance -->
                    <td
                      [ngClass]="getColorClass(item.upstreamDownstream.scope)"
                      (click)="openOverlayStep3($event, 'scope', item, i)"
                    >
                      {{ item.upstreamDownstream.scope }}
                    </td>
                    <!-- Irremediabilidad -->
                    <td
                      [ngClass]="getColorClass(item.upstreamDownstream.irremediability)"
                      (click)="openOverlayStep3($event, 'irremediability', item, i)"
                    >
                      {{ item.upstreamDownstream.irremediability }}
                    </td>
                    <!-- Materialidad -->
                    <td
                      (click)="openOverlayStep3($event, 'material_impact', item, i)"
                    >
                      {{ item.upstreamDownstream.material_impact ? 'Sí' : 'No' }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="legend">
                <label>Código de colores:</label>
                <table class="color-code-table">
                  <tr>
                    <td class="low">1</td>
                    <td class="medium-low">2</td>
                    <td class="medium">3</td>
                    <td class="medium-high">4</td>
                    <td class="high">5</td>
                  </tr>
                </table>
              </div>
              } @else {
              <div
                class="flex w-full align-items-center justify-content-center p-5"
              >
                No hay impactos registrados
              </div>
              }
            </ion-col>
          </ion-row>

          <!-- Overlay Panel Global -->
          <p-overlayPanel #op>
            <div style="width: 15vw" class="flex flex-column">
              <ng-container
                *ngIf="selectedFieldStep3 === 'material_impact' && selectedItemStep3"
              >
                <p-dropdown
                  class="p-2"
                  [options]="yesNoOptions"
                  [(ngModel)]="selectedItemStep3.material_impact"
                  (onChange)="updateValueStep3(op, selectedIndexStep3, selectedFieldStep3, selectedItemStep3.material_impact)"
                  placeholder="Seleccione una opción"
                ></p-dropdown>
              </ng-container>
              <ng-container
                *ngIf="selectedFieldStep3 !== 'material_impact' && selectedItemStep3"
              >
                <p-dropdown
                  class="p-2"
                  [options]="numberScale"
                  [(ngModel)]="selectedItemStep3[selectedFieldStep3]"
                  (onChange)="updateValueStep3(op, selectedIndexStep3, selectedFieldStep3, selectedItemStep3[selectedFieldStep3])"
                  placeholder="Seleccione un valor"
                ></p-dropdown>
              </ng-container>
            </div>
          </p-overlayPanel>
        </ion-grid>
        
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 7 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" class="step5-table">
              <label>LCA por análisis de materialidad financiera</label>
              @if(impactRiskOportunity.length > 0){
                <table class="impact-table mt-2">
                  <thead>
                    <tr>
                      <th rowspan="2">Ilustración<br />Impacto negativo</th>
                      <th colspan="3">Evaluación de la gravedad</th>
                      <th rowspan="2">
                        ¿Se considera que el impacto es material?
                      </th>
                    </tr>
                    <tr>
                      <th>Magnitud</th>
                      <th>Alcance</th>
                      <th>Carácter irremediable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of impactRiskOportunity; let i = index">
                      @if(item.id){
                        <td>{{ item.id }}</td>
                      } @else {
                        <td>{{ 'Nuevo impacto creado' }}</td>
                      }
                      <!-- Magnitud -->
                      <td
                        [ngClass]="getColorClass(item.financialMateriality.magnitude)"
                        (click)="openOverlayStep4($event, 'magnitude', item, i)"
                      >
                        {{ item.financialMateriality.magnitude }}
                      </td>
                      <!-- Alcance -->
                      <td
                        [ngClass]="getColorClass(item.financialMateriality.scope)"
                        (click)="openOverlayStep4($event, 'scope', item, i)"
                      >
                        {{ item.financialMateriality.scope }}
                      </td>
                      <!-- Irremediabilidad -->
                      <td
                        [ngClass]="getColorClass(item.financialMateriality.irremediability)"
                        (click)="openOverlayStep4($event, 'irremediability', item, i)"
                      >
                        {{ item.financialMateriality.irremediability }}
                      </td>
                      <!-- Materialidad -->
                      <td
                        (click)="openOverlayStep4($event, 'material_impact', item, i)"
                      >
                        {{ item.financialMateriality.material_impact ? 'Sí' : 'No' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="legend">
                  <label>Código de colores:</label>
                  <table class="color-code-table">
                    <tr>
                      <td class="low">1</td>
                      <td class="medium-low">2</td>
                      <td class="medium">3</td>
                      <td class="medium-high">4</td>
                      <td class="high">5</td>
                    </tr>
                  </table>
                </div>
              } @else {
                <div
                  class="flex w-full align-items-center justify-content-center p-5"
                >
                  No hay impactos registrados
                </div>
              }
            </ion-col>
          </ion-row>

          <!-- Overlay Panel Global -->
          <p-overlayPanel #op>
            <div style="width: 15vw" class="flex flex-column">
              <ng-container
                *ngIf="selectedFieldStep4 === 'material_impact' && selectedItemStep4"
              >
                <p-dropdown
                  class="p-2"
                  [options]="yesNoOptions"
                  [(ngModel)]="selectedItemStep4.material_impact"
                  (onChange)="updateValueStep4(op, selectedIndexStep4, selectedFieldStep4, selectedItemStep4.material_impact)"
                  placeholder="Seleccione una opción"
                ></p-dropdown>
              </ng-container>
              <ng-container
                *ngIf="selectedFieldStep4 !== 'material_impact' && selectedItemStep4"
              >
                <p-dropdown
                  class="p-2"
                  [options]="numberScale"
                  [(ngModel)]="selectedItemStep4[selectedFieldStep4]"
                  (onChange)="updateValueStep4(op, selectedIndexStep4, selectedFieldStep4, selectedItemStep4[selectedFieldStep4])"
                  placeholder="Seleccione un valor"
                ></p-dropdown>
              </ng-container>
            </div>
          </p-overlayPanel>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 8 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        @if(impactMatrixGroupData?.matrixData !== undefined || impactMatrixGroupData?.matrixData){
          <div class="table-container">      
            <div class="horizontal-header-container">
              <div class="scrollable-table">
                <label>Matriz de impactos</label>
                <table class="risk-matrix-table mt-1">
                  <thead>
                    <tr>
                      <th></th>
                      <th *ngFor="let financialRelevance of financialRelevances">
                        {{ getAxisLabel(financialRelevance) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let impact of impacts; let rowIndex = index">
                      <td class="header-cell">{{ getAxisLabel(impact) }}</td>
                      <td
                        *ngFor="let financialRelevance of financialRelevances; let colIndex = index"
                        [ngClass]="getCellClass(rowIndex, colIndex)"
                      >
                        <div class="chip-container">
                          <div
                            class="chip"
                            *ngFor="let item of impactMatrixGroupData?.matrixData[financialRelevance]?.[impact] || []"
                          >
                            <p-chip
                              [icon]="'pi pi-info-circle'"
                              (click)="showIndicatorsByChip(item)"
                              label="Impacto {{ item.id }}"
                              styleClass="custom-chip"
                              class="click-pointer"
                            ></p-chip>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        } @else {
          <div class="flex w-full justify-content-center">
            <p>Para visualizar la matriz de impactos, debe añadir al menos un registro en el paso 5</p>
          </div>
        }
        
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 9 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <form [formGroup]="form5">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <div class="flex align-items-center justify-content-center">
                  <strong>Evaluación de impacto y relevancia</strong>
                  <i
                    (click)="showMaterialImpacts()"
                    class="pi pi-eye icon-eye"
                    pTooltip="Ver listado de impactos materiales"
                  ></i>
                </div>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Impacto</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="impact"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="impact_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 1)"
                    (onSelect)="onFileSelect($event, 3, 1)"
                    (onRemove)="deleteFileSelected($event, 3, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[3] && existingFiles[3][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[3][1])"
                    ></p-button>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Relevancia financiera</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="financial_relevance"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="financial_relevance_text"
                  ></textarea>
                  <p-fileUpload
                    #fileUpload
                    name="file"
                    [multiple]="false"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 2)"
                    (onSelect)="onFileSelect($event, 3, 2)"
                    (onRemove)="deleteFileSelected($event, 3, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[3] && existingFiles[3][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[3][2])"
                    ></p-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>
        <div>
          <small
            >*Debe asignar un valor para impacto y relevancia financiera para
            que el indicador se muestre en la matriz de riesgos</small
          >
        </div>
        
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 10 -->
    <p-stepperPanel>
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <label for="">Título para el campo del formulario</label>
              <input
                type="text"
                pInputText
                [style]="{'width':'100%'}"
                [(ngModel)]="label_field"
              />
            </ion-col>
          </ion-row>
          <!-- Guardar campo del formulario -->
          <div class="save-form-field">
            <p-button
              (click)="updateStep7FormFields()"
              label="Añadir campo"
            ></p-button>
          </div>
        </ion-grid>

        <p-divider class="w-full" />
        
        <ion-grid>
          <ion-row>
            <!-- Creación -->
            @if(!edit){
              @for(item of dynamicFormArrayStep7; track item){
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  {{ item.label_field }}
                  <i
                    class="pi pi-times-circle icon-delete-field"
                    (click)="deleteFormField(item)"
                    pTooltip="Eliminar campo del formulario"
                  ></i>
                  <textarea
                    pInputTextarea
                    class="w-full mb-3 mt-1"
                    [style]="{'width':'100%'}"
                    rows="5"
                    [(ngModel)]="item.value"
                  ></textarea>
                  <p-fileUpload
                    [name]="'file-' + item.id"
                    [customUpload]="true"
                    (uploadHandler)="onFileUpload($event, item)"
                    accept=".pdf"
                    chooseLabel="Subir archivo"
                    [auto]="true"
                  ></p-fileUpload>
                </ion-col>
              } 
              
              @if (dynamicFormArrayStep7.length === 0) {
                <div class="flex w-full text-center justify-content-center p-2">
                  No hay nuevos campos definidos en el formulario
                </div>
              }
            } @else { 
              <!-- Edición -->
              @for(item of dynamicFormArrayStep7; track item){
                @if(!item.id){
                  <ion-col size-xs="12" size-sm="12" size-md="12">
                    {{ item.label_field }}
                    <i
                      class="pi pi-times-circle icon-delete-field"
                      (click)="deleteFormField(item)"
                      pTooltip="Eliminar campo del formulario"
                    ></i>
                    <textarea
                      pInputTextarea
                      class="w-full mb-3 mt-1"
                      [style]="{'width':'100%'}"
                      rows="5"
                      [(ngModel)]="item.value"
                    ></textarea>
                    <p-fileUpload
                      [name]="'file-' + item.id"
                      [customUpload]="true"
                      (uploadHandler)="onFileUpload($event, item)"
                      accept=".pdf"
                      chooseLabel="Subir archivo"
                      [auto]="true"
                    ></p-fileUpload>
                  </ion-col>
                } @else {
                  <ion-col size-xs="12" size-sm="12" size-md="12">
                    <strong>{{ item.label_field }}</strong>
                    <i
                      class="pi pi-times-circle icon-delete-field ml-2"
                      (click)="deleteFormField(item)"
                      pTooltip="Eliminar campo del formulario"
                    ></i>
                    <i
                      class="pi pi-pencil c-pointer ml-2"
                      (click)="showFieldUpdateDialog(item)"
                      pTooltip="Editar campo y valor"
                    >
                    </i>
                  </ion-col>
                }
              } 
              
              @if (dynamicFormArrayStep7.length === 0) {
                <div class="flex w-full text-center justify-content-center p-2">
                  No hay nuevos campos definidos en el formulario
                </div>
              }
            }
          </ion-row>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Guardar"
            iconPos="right"
            [loading]="loadingForm"
            (click)="onSubmitForm()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</p-dialog>

<!-- Modal para selección de formulario -->
<p-dialog
  header="Seleccione una opción"
  [(visible)]="dialogForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw' }"
>
  <div class="p-4 align-items-center justify-content-center flex w-full gap-3">
    <p-button
      label="Doble materialidad a múltiples indicadores"
      (click)="formSelected(1)"
    ></p-button>
    <p-button
      label="Gestionar grupos para doble materialidad"
      (click)="formSelected(2)"
    ></p-button>
  </div>
</p-dialog>

<!-- Modal para historico de DM -->
<p-dialog
  header="Doble materialidad del indicador"
  [(visible)]="dialogDualMateriality"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
>
</p-dialog>

<!-- Modal para gestionar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <p-table
    #dt1
    styleClass="p-datatable-striped"
    responsiveLayout="scroll"
    [value]="dmGroups"
    [rowHover]="true"
    [paginator]="true"
    [rows]="5"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="caption">
      <div
        style="gap: 1vw"
        class="flex justify-content-center align-items-center sm:flex-row"
      >
        <input
          pInputText
          type="text"
          #filterGeneral
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Buscar grupo"
          class="w-full"
        />
        <p-button
          icon="pi pi-plus"
          pTooltip="Nuevo grupo"
          (click)="showDialog()"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre del grupo</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
      <tr>
        <td>{{ group.name }}</td>
        <td>
          <p-button
            class="m-1"
            pTooltip="Editar grupo"
            icon="pi pi-pencil"
            (click)="showDialog(group)"
          ></p-button>
          <p-button
            class="m-1"
            severity="danger"
            pTooltip="Eliminar grupo"
            icon="pi pi-trash"
            (click)="deleteGroup(group)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron grupos</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Modal para crear/editar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroupForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <form [formGroup]="formDMGroup">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">*Nombre del grupo</label>
          <input
            type="text"
            class="mb-3 w-full"
            [style]="{ width: '100%' }"
            pInputText
            formControlName="name"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="onSubmitGroupForm()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal de eliminación de DM -->
<p-dialog
  header="Eliminar registro de doble materialidad"
  [(visible)]="dialogDeleteDm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este registro?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará los datos
      relacionados.
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteDm(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteDm(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal de eliminación de grupo DM -->
<p-dialog
  header="Eliminar grupo de doble materialidad"
  [(visible)]="dialogDeleteGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este grupo?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará a todos los
      indicadores del grupo y sus datos
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteGroup(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteGroup(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal para matriz de riesgos de un grupo -->
<p-dialog
  [(visible)]="dialogRiskMatrix"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '95vw', height: '100vh' }"
  (onHide)="handleCloseMatrixDialog()"
>
  @if(this.matrixGroupData?.matrixData !== undefined){
    <div class="table-container">
      <div class="vertical-header">IMPACTO</div>
  
      <div class="horizontal-header-container">
        <div class="scrollable-table">
          <table class="risk-matrix-table">
            <!-- Elimina el encabezado desde el thead -->
            <tbody>
              <tr *ngFor="let impact of impacts; let rowIndex = index">
                <td class="header-cell">{{ getAxisLabelImpact(impact) }}</td>
                <td
                  *ngFor="let financialRelevance of financialRelevances; let colIndex = index"
                  [ngClass]="getCellClass(rowIndex, colIndex)"
                >
                  <div class="chip-container">
                    <div
                      class="chip"
                      *ngFor="let value of matrixGroupData?.matrixData[impact]?.[financialRelevance] || []; let chipIndex = index"
                    >
                      <p-chip
                        [icon]="'pi pi-info-circle'"
                        (click)="showIndicatorsByChipByDmGroup(value)"
                        label="{{ value.name }}"
                        styleClass="custom-chip"
                        class="click-pointer"
                      ></p-chip>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- Mueve la fila del encabezado aquí -->
              <tr>
                <th></th>
                <th *ngFor="let financialRelevance of financialRelevances">
                  {{ getAxisLabel(financialRelevance) }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="horizontal-header bottom-header">RELEVANCIA FINANCIERA</div>
      </div>
    </div>
  }
</p-dialog>

<!-- Modal para mostrar indicadores de un grupo -->
<p-dialog
  header="Indicadores incluidos"
  [(visible)]="dialogMatrixClicked"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw'}"
  (onHide)="indicatorsByDmGroupClicked = null"
>
  @if(indicatorsByDmGroupClicked){
  <p-dataView
    #dv
    [value]="indicatorsByDmGroupClicked['indicatorsData']"
    [rows]="5"
    [paginator]="true"
  >
    <ng-template pTemplate="list" let-items>
      <div class="grid grid-nogutter">
        <div
          class="col-12"
          *ngFor="let item of items; let first = first"
          class="col-12"
        >
          <span>- {{ item?.name }}</span>
        </div>
      </div>
    </ng-template>
  </p-dataView>
  }
</p-dialog>

<!-- Modal para creación/edición de registro de impacto -->
<p-dialog
  header="{{ editImpact ? 'Edición de registro' : 'Creación de registro'}}"
  [(visible)]="dialogImpactRisk"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw'}"
  (onHide)="handleCloseImpactDialog()"
>
  <form [formGroup]="formImpact">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <!-- Si creo dm y creo impacto o creo dm y edito impacto en local -->
          @if((!edit && !editImpact) || (!edit && editImpact) || (edit && !editImpact) || (edit && editImpact && !selectedImpactRegister)){
            <label for="">*Indicadores</label>
            <div class="mt-1">
              <p-listbox 
                [options]="indicators" 
                [(ngModel)]="selectedIndicators" 
                optionValue="id"
                optionLabel="name"
                [multiple]="true"
                [filter]="true"
                filterPlaceHolder="Buscar indicador"
  
                [style]="{'width':'100%'}"
                [listStyle]="{'height': '220px'}"
                [ngModelOptions]="{ standalone: true }"
                class="w-full md:w-56"
              >
                <ng-template let-indicator pTemplate="item">
                  <div class="flex items-center gap-2">
                    <div>{{ indicator.name + ' - ' + indicator.parent }}</div>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          }

          <!-- Listado de indicadores de un impacto ya creado previamente -->
          @if(edit && editImpact && selectedImpactRegister){
            <div class="w-full">
              <label for="">Indicadores del impacto</label>
              @for(item of selectedImpactRegister['dual_mat_impact_indicator']; track item){
                <ul>
                  <span>{{ item?.indicator?.name }}</span>
                </ul>
              }
            </div>
          }
        </ion-col>

        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="">*Impacto</label>
          <textarea
            class="w-full mb-3 mt-1"
            formControlName="impact_form_impact_text"
            pInputTextarea
            rows="5"
          ></textarea>
          <p-fileUpload
            #fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
            (onSelect)="onFileSelectImpact($event, 'impact')"
            (onRemove)="deleteFileSelectedImpact('impact')"
          ></p-fileUpload>

          <!-- Abir documento existente de impacto -->
          <div
            class="button-container"
            *ngIf="editImpact && selectedImpactRegister?.impact_document"
          >
            <p-button
              icon="pi pi-file"
              label="Abrir documento"
              styleClass="w-full mt-2"
              (click)="openDocument(selectedImpactRegister?.impact_document)"
            ></p-button>
          </div>
        </ion-col>

        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="">*Riesgo</label>
          <textarea
            class="w-full mb-3 mt-1"
            formControlName="impact_form_risk_text"
            pInputTextarea
            rows="5"
          ></textarea>
          <p-fileUpload
            #fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
            (onSelect)="onFileSelectImpact($event, 'risk')"
            (onRemove)="deleteFileSelectedImpact('risk')"
          ></p-fileUpload>

          <!-- Abir documento existente de riesgo -->
          <div
            class="button-container"
            *ngIf="editImpact && selectedImpactRegister?.risk_document"
          >
            <p-button
              icon="pi pi-file"
              label="Abrir documento"
              styleClass="w-full mt-2"
              (click)="openDocument(selectedImpactRegister?.risk_document)"
            ></p-button>
          </div>
        </ion-col>

        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">*Oportunidad</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            formControlName="impact_form_opportunity_text"
            rows="5"
          ></textarea>
          <p-fileUpload
            #fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
            (onSelect)="onFileSelectImpact($event, 'opportunity')"
            (onRemove)="deleteFileSelectedImpact('opportunity')"
          ></p-fileUpload>

          <!-- Abir documento existente de oportunidad -->
          <div
            class="button-container"
            *ngIf="editImpact && selectedImpactRegister?.opportunity_document"
          >
            <p-button
              icon="pi pi-file"
              label="Abrir documento"
              styleClass="w-full mt-2"
              (click)="openDocument(selectedImpactRegister?.opportunity_document)"
            ></p-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button
      [loading]="loadingForm"
      label="Guardar"
      (click)="onPreviewSubmitImpactRegister()"
    ></p-button>
  </ng-template>
</p-dialog>

<!-- Modal para aviso de guardado de impacto -->
<p-dialog
  header="Aviso de guardado"
  [(visible)]="dialogPreviewSubmit"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="w-full flex align-items-center justify-content-center">
          <p>
            Los cambios realizados en este formulario se guardarán de forma
            definitiva al pulsar <strong>Guardar</strong> en la última sección.
          </p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ng-template pTemplate="footer">
    <p-button label="Entendido" (click)="closeModalImpactForm()"></p-button>
  </ng-template>
</p-dialog>

<!-- Modal de confirmación de descarga de informe -->
<p-dialog
  header="Confirmación de descarga de informe"
  [(visible)]="dialogConfirmationReportDocument"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseDialogConfirmationReportDocument()"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">{{ '¿Está seguro de que desea generar un documento para el grupo ' + selectedReportGroup?.name + ' ?'}}</p>
    <div class="flex justify-content-center gap-3 p-3">
      <p-button
        [loading]="loadingReport"
        label="Sí"
        icon="pi pi-check"
        (click)="generateReportDocument(true)"
      ></p-button>
      <p-button
        [loading]="loadingReport"
        label="No"
        icon="pi pi-times"
        (click)="generateReportDocument(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal para ver tabla de impactos recien creados en el formulario -->
<p-dialog
  header="Lista de impactos materiales"
  [(visible)]="showMaterialImpactsDialog"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseDialogConfirmationReportDocument()"
  [style]="{ width: '90vw'}"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-table
          #dt1
          styleClass="p-datatable-striped"
          responsiveLayout="scroll"
          [value]="materialImpacts"
          [rowHover]="true"
          [paginator]="true"
          [rows]="5"
          [globalFilterFields]="['impact_form_impact_text', 'impact_form_risk_text', 'impact_form_opportunity_text']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Identificador</th>
              <th>Impactos</th>
              <th>Riesgos</th>
              <th>Oportunidades</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.impact_form_impact_text }}</td>
              <td>{{ item.impact_form_risk_text }}</td>
              <td>{{ item.impact_form_opportunity_text }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No se encontraron impactos materiales registrados</td>
            </tr>
          </ng-template>
        </p-table>
      </ion-col>
    </ion-row>
  </ion-grid>
</p-dialog>

<!-- Modal para mostrar niveles e indicadores de un dm -->
<p-dialog
  header="Niveles e indicadores"
  [(visible)]="showIndicatorsByDmLevelDialog"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseIndicatorsByDmLevelDialog()"
  [style]="{ width: '60vw'}"
>
  @if(dmSelected){
    <div class="w-full">
      @for(item of dmSelected['parent']; track item){
        <ul>
          <span>
            <strong>{{ item?.parentLabel }}</strong>
          </span>
          <div class="mt-3">
            @for(subitem of item['indicator']; let i = $index; track subitem){
              <li>{{ subitem }}</li>
            }
          </div>
        </ul>
      }
    </div>
  }
</p-dialog>

<!-- Modal para mostrar niveles e indicadores de un dm en detalle -->
<p-dialog
  header="Indicadores incluidos en el impacto"
  [(visible)]="showIndicatorsByDmDetailDialog"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseIndicatorsByDmLevelDialog()"
  [style]="{ width: '60vw'}"
>
  @if(dmSelected){
    <div class="w-full">
      @for(item of dmSelected['dual_mat_impact_indicator']; track item){
        <ul>
          <li>
            <span>{{ item?.indicator?.name }}</span>
          </li>
        </ul>
      }
    </div>
  }
</p-dialog>

<p-dialog
  header="Editar campo dinámico"
  [(visible)]="dialogFieldSelected"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseMatFieldDialog()"
  [style]="{ width: '60vw'}"
>
  <form [formGroup]="formUpdateField">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">*Título para el campo del formulario</label>
          <input
            type="text"
            pInputText
            [style]="{'width':'100%'}"
            formControlName="label_field"
          />
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">Valor para el campo del formulario</label>
          <textarea
            pInputTextarea
            class="w-full mb-3 mt-1"
            [style]="{'width':'100%'}"
            rows="5"
            formControlName="value"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">Documento</label>
          <p-fileUpload
            [customUpload]="true"
            (uploadHandler)="onFileUploadFields($event, matFieldSelected)"
            accept=".pdf"
            chooseLabel="Subir archivo"
            [auto]="true"
          ></p-fileUpload>
          @if(matFieldSelected && matFieldSelected.document){
            <div class="mt-3">
              <p-button
                icon="pi pi-file"
                label="Abrir documento"
                (click)="openDocument(matFieldSelected.document)"
              ></p-button>
            </div>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmitMatFields()"></p-button>
  </ng-template>
</p-dialog>

<!-- Modal para mostrar tabla de valores de paso 2 de dm -->
<p-dialog
  header="Tabla con valores actuales"
  [(visible)]="dialogStep2Table"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleCloseDialogStep2Table()"
  [style]="{ width: '60vw'}"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-table
          #dt1
          styleClass="p-datatable-striped"
          responsiveLayout="scroll"
          [value]="stakeholdersSelected"
          [rowHover]="true"
          [paginator]="true"
          [rows]="5"
          [globalFilterFields]="['name']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Parte interesada</th>
              <th>Nivel de influencia (1-5)</th>
              <th>Impacto en los riesgos (1-5)</th>
              <th>Puntuación total (influencia x impacto)</th>
              <th>Prioridad</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item?.name }}</td>
              <td>{{ item?.influence_level }}</td>
              <td>{{ item?.impact_risks }}</td>
              <td>{{ item?.total }}</td>
              <td>{{ item?.priority }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No se encontraron registros</td>
            </tr>
          </ng-template>
        </p-table>
      </ion-col>
    </ion-row>
  </ion-grid>
</p-dialog>