import { ChangeDetectorRef, Component, NgZone, OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { marked } from 'marked';
import { ServerService } from 'src/app/services/server.service';
import { Location } from '@angular/common';
import { Indicator } from 'src/app/models/indicator';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/services/events.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-ai',
  templateUrl: './chat-ai.component.html',
  styleUrls: ['./chat-ai.component.scss'],
})
export class ChatAIComponent implements OnInit {
  userMessage: string = ''; // Mensaje del usuario
  reviewedContent: string = '';
  messages: { 
    text: any; sender: 'user' | 'bot', 
    class : 'chat-messages__user' | 'chat-messages__bot' 
  }[] = []; // Mensajes del chat
  messagesNarrative: { 
    text: any; sender: 'user' | 'bot', 
    class : 'box-messages__user' | 'box-messages__bot'
    index?: number
  }[] = []; // Mensajes del chat de narrativos
  isModalOpen = false;
  showNewWindow = false;
  suggestions: string[];
  currentSuggestion: number[] = [];
  selectedTopics: string[] = [];
  suggestionError = false;
  isLoading: boolean = false;
  editor: any;
  isExpanded: boolean = false; // Estado para saber si el modal está ampliado
  qualitativeIndicatorSelected: Indicator | null = null;
  options: string[] = [];
  userResponseHandler: string[] = [];
  canInsertResponse: boolean;
  selectedOption: number;
  optionError: boolean = false;
  indicator: Indicator;
  isLoadingContent: boolean = false;
  private eventSubscription: Subscription;
  
  constructor(
    private http: HttpClient,
    private serverService: ServerService,
    private location: Location,
    private utilities: UtilitiesService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private eventService: EventsService,
  ) {}

  ngOnInit(){
    this.getSuggestions();

    this.options = ['Sugerir texto', 'Revisar con IA'];
    this.userResponseHandler = ['Reemplazar texto', 'Anexar texto', 'Volver a generar texto'];

    //Suscripción al evento que se ha emitido, se mantiene a la escucha desde que el componente está activo
    this.eventSubscription = this.eventService.events$.subscribe((event) => {
      if (event.event === 'chatAI') {
        console.log('Llamo a evento chatAI');
        this.dismiss();
      }
    });
  }

  /**
   * Scroll completo el chat hacia abajo del modal
   */
  private scrollToBottom(): void {
    setTimeout(() => {
      const container = document.querySelector('.chat-messages');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, 0);
  }

  /**
   * Manejo para cerrar modal de chat
   */
  dismiss() {
    this.isModalOpen = false;
    this.qualitativeIndicatorSelected = null;
  }

  toggleModalSize() {
    this.isExpanded = !this.isExpanded; // Alterna el estado entre ampliado y normal
  }

  /**
   * 
   */
  getSuggestions(){
    // Enviar datos a la API de IA
    this.serverService.sendData('/api/chat-ia/suggestions').subscribe({
      next: (response) => {
        this.suggestions = response.sugerencias;
        this.scrollToBottom();
      },
      error: (error) => {
        console.error('Error en la API', error);
      },
    });
  }

  /**
   * Obtener último documento del indicador narrativo
   * @param documents 
   * @returns 
   */
  getLastIndicatorContent(documents: any[]): any | null {
    if (!documents || documents.length === 0) {
      return null; // Retorna null si el array está vacío o indefinido
    }

    const lastDocument = documents.reduce((latest, current) => {
      return new Date(current.created_at) > new Date(latest.created_at) ? current : latest;
    }, documents[0]); // Usa el primer elemento como valor inicial

    return lastDocument; // Asegúrate de retornar el último documento
  }

  /**
   * Método para sugerir contenido del indicador,
   * Recoge datos de la norma a la que pertenece, el nombre del indicador y la descripción del indicador
   * y lo envía por post a la API de IA, devuelve el texto sugerido para autocompletar el textarea
   */
  suggestContent(){
    if(this.qualitativeIndicatorSelected){
      this.isLoadingContent = true;
      const lastContent = this.getLastIndicatorContent(this.qualitativeIndicatorSelected['history_indicator_document']);

      if(lastContent){
        // Procesado de datos
        this.serverService.sendData(`/api/chat-ia/suggest-content/${this.qualitativeIndicatorSelected.id}`, 
          {
            nombre: this.qualitativeIndicatorSelected.name,
            descripcion: this.qualitativeIndicatorSelected.description,
            contenido: lastContent,
          }
        ).subscribe({
          next: (response) => {
            // Ejecutar dentro de la zona de Angular
            this.zone.run(() => {
              this.isLoadingContent = false;
              this.canInsertResponse = true;  
              this.messagesNarrative.push(
                { 
                  text: marked.parse(response.data.message.content), 
                  sender: 'bot', class: 'box-messages__bot'
                }
              );
              this.cdr.detectChanges();
            });
          },
          error: (error) => {
            this.isLoadingContent = false;
            // Asegurarse de que los errores también se manejan dentro de la zona de Angular
            this.zone.run(() => {
              this.isLoadingContent = false;
              this.cdr.detectChanges();
              console.error('Error en la API', error);
            });
          }
        })
      } else {
        this.isLoadingContent = false;
        this.zone.run(() => {
          this.isLoadingContent = false;
          this.messagesNarrative.push(
            { 
              text: 'No se ha encontrado ningún documento para sugerir contenido.', 
              sender: 'bot', class: 'box-messages__bot'
            }
          );
          this.cdr.detectChanges();
        });
      }
    } else {
      this.isLoadingContent = false;
      this.zone.run(() => {
        this.isLoadingContent = false;
        this.messagesNarrative.push(
          { 
            text: 'No se ha seleccionado un indicador narrativo.', 
            sender: 'bot', class: 'box-messages__bot'
          }
        );
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Selección de opciones tras recibir respuesta de la IA
   * @param index 
   */
  doHandler(index: number){
    switch (index) {
      case 0:
        // Emito evento con el nuevo texto al quill
        this.messagesNarrative[this.messagesNarrative.length - 1].index = 0;
        this.eventService.emitEvent('quill', this.messagesNarrative[this.messagesNarrative.length - 1]);
        break;
      case 1:
        this.messagesNarrative[this.messagesNarrative.length - 1].index = 1;
        this.eventService.emitEvent('quill', this.messagesNarrative[this.messagesNarrative.length - 1]);
        break;
      case 2:
        this.suggestContent();
        break;
      default:
        console.warn('Opción no seleccionada');
        break;
    }
  }

  executeOption(){
    switch (this.selectedOption) {
      case 0:
        this.suggestContent();
        break;
      case 1:
        this.reviewContent();
        break;
      default:
        console.warn('Opción no seleccionada');
        break;
    }
  }

  /**
   * Método que muestra un modal para ingresar el texto con idicaciones para que la IA trabaje sobre el contenido y haga
   * la revisión solicitada enviando por post esos datos. Recibe el texto sugerido por la IA y lo muestra en un modal
   */
  reviewContent(){
    const lastContent = this.getLastIndicatorContent(this.qualitativeIndicatorSelected?.['history_indicator_document']);
    if(lastContent){
      this.messagesNarrative.push(
        { 
          text: this.userMessage, 
          sender: 'user', 
          class: 'box-messages__user'}
      );
  
      this.isLoadingContent = true;
      this.cdr.detectChanges();
  
      //enviar datos a la API de IA
      this.serverService.sendData(`/api/chat-ia/review-content/${this.qualitativeIndicatorSelected?.id}`,
        {
          nombre: this.qualitativeIndicatorSelected?.name,
          descripcion: this.qualitativeIndicatorSelected?.description,
          contenido: lastContent,
          solicitud: this.userMessage,         
        }
      ).subscribe({
        next: (response) => {
          // Ejecutar dentro de la zona de Angular
          this.zone.run(() => {
            this.isLoadingContent = false;
            this.reviewedContent = response.review;
            this.canInsertResponse = true;
            this.messagesNarrative.push(
              { 
                text: marked.parse(response.data.message.content), 
                sender: 'bot',
                class: 'box-messages__bot'
              }
            );
            this.userMessage = '';
            this.cdr.detectChanges();
          });
        },
        error: (error) => {
          console.error('Error al obtener indicadores', error);
          // Asegurarse de que los errores también se manejan dentro de la zona de Angular
          this.zone.run(() => {
            console.error('Error en la API', error);
          });
        },
      });
    } else {
      this.isLoadingContent = false;
      this.zone.run(() => {
        this.messagesNarrative.push(
          { 
            text: marked.parse("No se ha encontrado ningún documento para revisar contenido."), 
            sender: 'bot',
            class: 'box-messages__bot'
          }
        );
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Definir opción seleccionada en chat AI cuando es indicador narrativo
   * @param index 
   */
  setOption(index: number){
    this.selectedOption = index;
    this.optionError = false;

    this.canInsertResponse = false;
    if (this.selectedOption === 0) {
      this.suggestContent();
    }
  }

  setSuggestion(index: number, suggestion: string){
    if (this.currentSuggestion.includes(index)) {
      this.currentSuggestion.splice(this.currentSuggestion.indexOf(index), 1);
      this.selectedTopics.splice(this.selectedTopics.indexOf(suggestion), 1);
    } else {
      this.currentSuggestion.push(index);
      this.selectedTopics.push(suggestion);
    }
    this.suggestionError = false;
  }

  /**
   * Procesado de contenido de chat para enviar a la API de IA
   * @returns 
   */
  sendMessage() {
    // Aviso visual para seleccionar alguna de las cuatro temáticas disponibles
    if (this.currentSuggestion.length === 0) {
      this.suggestionError = true; // Resalta los botones en rojo
      console.warn('No hay sugerencia seleccionada');
      return;
    }

    if(this.userMessage !== ''){ // Si hay mensaje, se muestra en parte del usuario del chat
      this.messages.push(
        { 
          text: this.userMessage,
          sender: 'user', 
          class: 'chat-messages__user'
        }
      );
    } else if(this.currentSuggestion.length > 0) { // Sino, mostrar temáticas seleccionadas
      this.messages.push(
        { 
          text: this.selectedTopics.join(', '), 
          sender: 'user', 
          class: 'chat-messages__user'
        }
      );
    }

    this.isLoading = true;
    this.scrollToBottom();

    const messageToSend = this.userMessage; // Guardar el mensaje para enviar
    const indexToSend = this.currentSuggestion;
    this.userMessage = ''; // Limpiar el textarea

    // Enviar datos a la API de IA
    this.serverService.sendData('/api/chat-ia/chat',
      {
        message: messageToSend, 
        suggestionsId: indexToSend          
      }
    ).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.simulateTypingEffect(marked.parse(response.data.message.content));
        this.scrollToBottom();
        this.isLoading = false;       
      },
      error: (error) => {
        console.error('Error en la petición al API-IA', error);
        this.isLoading = false;
      },
    });
  }

  /**
   * Simulación de efecto de escritura en chat AI
   * @param message 
   */
  private simulateTypingEffect(message: any) {
    const botMessage:any = { text: '', sender: 'bot', class: 'chat-messages__bot' };
    this.messages.push(botMessage); 

    let index = 0;       
    let delay = 50;     

    const typeCharacter = () => {
      if (index < message.length) {
        botMessage.text += message[index];
        index++;
        delay = Math.max(10, delay - 1);
        if (index === 500) {
          botMessage.text = message; 
          this.scrollToBottom();
          return;
        }
        setTimeout(typeCharacter, delay);
        this.scrollToBottom();
      }
    };

    typeCharacter();
  }

  /**
   * Manejar cierre del modal de chat IA
   */
  handleCloseChatAI(){
    this.qualitativeIndicatorSelected = null;
  }

  /**
   * Abrir modal de chat en la pantalla actual
   */
  openModal(){
    // Comprobamos si estamos en un indicador narrativo y poder cambiar contenido del chat AI
    const indicatorResponse = this.utilities.getState();
    if(indicatorResponse){
      this.qualitativeIndicatorSelected = indicatorResponse;
    }

    this.isModalOpen = true;
    this.getSuggestions();
  }

  /**
   * Abrir nueva ventana para chat AI
   */
  openDialogInNewWindow(){
    const urlCompleta = this.location.prepareExternalUrl('/chat-ai-new-window');
    const nuevaVentana: any = window.open(
      urlCompleta, // URL del componente
      '_blank', // Nombre de la ventana
      'width=800,height=600,scrollbars=yes,resizable=yes' // Características de la ventana
    );

    // Si estamos en narrativo, lo guardamos en storage de manera temporal hasta que abramos el modal
    if(this.qualitativeIndicatorSelected){
      localStorage.setItem('narrativeIndicatorSelected', JSON.stringify(this.qualitativeIndicatorSelected));
    }

    this.isModalOpen = false;
  }
}
