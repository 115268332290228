<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label>Cargando proveedores...</ion-label>
  </div>
}

@if(!loading){
  <ion-grid class="full-grid">
    <!-- Header -->
    <ion-row>
      <div class="header">
        <div class="back-button-container">
          @if(!loading){
            <div class="back-btn">
              <p-button
                (click)="goBack()"
                icon="pi pi-arrow-left"
                [outlined]="true"
                label="Volver"
                [style]="{ 'color': 'white', 'border-color': 'white' }"
              >
              </p-button>
            </div>
          }
        </div>
      </div>
    </ion-row>

    <!-- Primera fila -->
    <ion-row class="p-4 row1">
      <ion-col size-xs="12" size-sm="12" size-md="3">
        <div class="options-container">
          <p-listbox 
            [options]="userDetailOptions" 
            [(ngModel)]="selectedUserOption" 
            optionLabel="label" 
            optionValue="value" 
            class="w-full md:w-56" 
          />
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="9">
        <div class="item-container">
          <ion-item lines="none">
            <div class="circle-container">
              <div class="circle">
                <span>
                  {{ shortUserName ? shortUserName : '-' }}
                </span>
              </div>
  
              <div class="circle-texts">
                <label class="title1">{{ companySelected?.name }}</label>
                <label class="title2">{{ currentUser.user.name }}</label>
              </div>
            </div>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>

    <!-- Segunda fila -->
    <ion-row class="pr-4 pl-4 row2">
      <ion-col size-xs="12" size-sm="12" size-md="3">
        <!-- Vacio -->
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="9">
        <!-- Formulario -->
        @if(selectedUserOption === 1 || selectedUserOption === 2){
          <form [formGroup]="formDetail">
            <div class="form-container">
              <ion-grid class="p-4">
                <ion-row>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label for="name col-6">Razón social</label>
                    <div class="mt-1">
                      <input
                        [(ngModel)]="companySelected.name"
                        [ngModelOptions]="{ standalone: true }"
                        pInputText
                        class="mt-1 w-full"
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label for="name col-6">CIF</label>
                    <div class="mt-1">
                      <input
                        [(ngModel)]="companySelected.cif"
                        [ngModelOptions]="{ standalone: true }"
                        pInputText
                        class="mt-1 w-full"
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label for="name col-6">Responsable</label>
                    <div class="mt-1">
                      <input
                        [disabled]="true"
                        pInputText
                        formControlName="responsible"
                        class="mt-1 w-full"
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Fecha de entrada</label>
                    <div class="mt-1">
                      <p-calendar
                        appendTo="body"
                        [style]="{'width':'100%'}"
                        [inputStyle]="{'width':'100%'}"
                        class="calendar-form"
                        formControlName="entry_date"
                        dateFormat="dd/mm/yy"
                        [disabled]="true"
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Dirección</label>
                    <input 
                      pInputText                 
                      [disabled]="true"
                      formControlName="address" 
                      class="mt-1 w-full"
                    />
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Código postal</label>
                    <input
                      pInputText
                      maxlength="5"
                      formControlName="zip_code"
                      class="mt-1 w-full"
                    />
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Ciudad</label>
                    <div class="mt-1">
                      <input                 
                        [disabled]="true" 
                        pInputText 
                        formControlName="city" 
                        class="mt-1 w-full" 
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Provincia</label>
                    <div class="mt-1">
                      <input 
                        pInputText                 
                        [disabled]="true" 
                        formControlName="province" 
                        class="mt-1 w-full" 
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>País</label>
                    <div class="mt-1">
                      <input 
                        pInputText 
                        formControlName="country"                  
                        [disabled]="true"
                        class="mt-1 w-full" 
                      />
                    </div>
                  </ion-col>
                  <ion-col size-xs="12" size-sm="12" size-md="6">
                    <label>Criticalidad</label>
                    <div class="mt-1">
                      <p-inputNumber
                        [disabled]="true"
                        [style]="{'width':'100%'}"
                        [inputStyle]="{'width':'100%'}"
                        class="mt-1 w-full"
                        inputId="integeronly"
                        formControlName="criticality"
                      />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
  
              <div class="w-full flex justify-content-end p-4">
                <p-button
                  label="Guardar"
                  (click)="onSubmitForm(1)"
                  [loading]="loadingForm"
                ></p-button>
              </div>
            </div>
          </form>
        }
        @if(selectedUserOption === 3){
          <div class="w-full form-container">
            <ion-grid>
              <ion-row>
                @if(this.currentUser.document){
                  <ion-col size-xs="12" size-sm="12" size-md="12">
                    <div class="p-4 flex w-full justify-content-center align-items-center">
                      <p-button
                        icon="pi pi-file"
                        label="Abrir documento guardado" 
                        (click)="openDocument()"
                      ></p-button>
                    </div>
                  </ion-col>
                }
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <p-fileUpload
                    name="file"
                    #fileUpload
                    chooseLabel="Seleccionar archivo"
                    cancelLabel="Cancelar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".pdf"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>

            <div class="w-full flex justify-content-end p-4">
              <p-button
                (click)="onSubmitForm(2)"
                label="Guardar archivo"
                [loading]="loadingForm"
              ></p-button>
            </div>
          </div>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
}