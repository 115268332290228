@if(!loading){
  <ul class="layout-menu">
    <li
      app-menuitem
      *ngFor="let item of model; let i = index"
      [item]="item"
      [index]="i"
      [model]="model"
      [root]="true"
      [ngClass]="test"
    ></li>
  </ul>
} 

@if(loading && this.model.length > 0) {
  <div class="spinner-container-menu">
    <ion-spinner name="dots"></ion-spinner>
  </div>
}
