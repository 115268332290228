import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
})
export class PopupMenuComponent  implements OnInit {
  sidebarVisible: boolean = false;
  constructor(    private eventService: EventsService,) { }

  ngOnInit() {

  }

  openMenu(items){
    this.sidebarVisible = true;
  }

}
