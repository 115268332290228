import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-user-supplier-detail',
  templateUrl: './user-supplier-detail.page.html',
  styleUrls: ['./user-supplier-detail.page.scss'],
})
export class UserSupplierDetailPage implements OnInit {
  @ViewChild('fileUpload') fileUpload!: FileUpload;
  userId: string | null;
  supplierId: string | null;
  companyId: string | null;
  loading: boolean = true;
  mainStringPhoto: any;
  shortUserName: string = '';
  currentUser: any;
  formDetail: FormGroup;
  companySelected: any;
  loadingForm: boolean = false;
  uploadedFile: File | null = null;
  userDetailOptions: any[] = [
    {
      label: 'Inicio',
      value: 1
    },
    {
      label: 'Información básica',
      value: 2
    },
    {
      label: 'Documentos',
      value: 3
    }
  ];
  selectedUserOption: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private serverService: ServerService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) { 
    this.companyId = localStorage.getItem('companyId');

    // Captura de parámetros 
    this.activatedRoute.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.supplierId = params.get('supplierId');
    });
  }

  ngOnInit() {
    this.getUserData();

    // Formulario específico
    this.formDetail = this.fb.group({
      responsible: [''],
      entry_date: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      province: [''],
      country: [''],
      criticality: [''],
      document: [''],
      document_hash: [''],
    });
  }

  /**
   * Obtener datos del proveedor seleccionado
   */
  getUserData(){
    this.serverService.getDataUsersApi(`/api/companies/${this.companyId}/suppliers/${this.supplierId}`).subscribe({
      next: (response) => {
        if(response.data){
          this.currentUser = response.data;
          this.companySelected = {
            id: response.data.user?.company_id,
            name: response.data.company?.company_name,
            cif: response.data.company?.cif ? response.data.company?.cif : 'Sin definir'
          }
          this.getInitials();
          this.formDetail.patchValue({
            ...response.data,
            entry_date: new Date(response.data.entry_date)
          });
        }
      },
      error: (err) => {
        this.loading = false;
        console.error('Error al obtener los datos del proveedor', err);
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  /**
   * Volver a pantalla anterior de pila de navegación
   */
  goBack() {
    history.back();
  }

  /**
   * Obtener iniciales del nombre del proveedor
   * @returns 
   */
  async getInitials(){
    const nameParts = this.currentUser.user.name.trim().split(/\s+/); // Dividir el nombre por espacios

    if (nameParts.length === 1) {
      // Si solo hay una palabra, devolver la inicial de ese nombre
      return this.shortUserName = nameParts[0].charAt(0).toUpperCase();
    }
  
    // Obtener la inicial del primer nombre y el primer apellido
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts[1].charAt(0).toUpperCase();
  
    this.shortUserName = firstNameInitial + lastNameInitial;
  }

  /**
   * Manejador de subida de archivo de evidencia
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Enviar formulario de datos o documento de proveedor
   * @param type 
   */
  onSubmitForm(type: number){
    if(type === 1){
      this.formDetail.markAllAsTouched();
      const formData = new FormData();

      Object.keys(this.formDetail.controls).forEach(key => {
        let controlValue = this.formDetail.get(key)?.value;
    
        // Verificar si es el campo "entry_date" y transformarlo
        if (key === 'entry_date' && controlValue) {
          controlValue = this.datePipe.transform(controlValue, 'yyyy-MM-dd');
        }
    
        // Verifica si el valor no es nulo ni indefinido
        if (controlValue !== null && controlValue !== undefined) {
          formData.append(key, controlValue);
        }
      });
    
      this.loadingForm = true;

      // Procesar datos del formulario
      this.serverService.sendDataUsersApi(`/api/companies/${this.companyId}/suppliers/${this.currentUser.id}`, formData).subscribe({
        next: (response) => {
          if(response.data){
            this.loadingForm = false;
            return this.messageService.add({
              severity:'success',
              summary: 'OK',
              detail: 'Proveedor actualizado correctamente'
            })
          }
        },
        error: (err) => {
          this.loadingForm = false;
          console.error('Error al enviar formulario de datos', err);
        }
      });
    } else if(type === 2){
      const formData = new FormData();
      
      if (this.uploadedFile) {
        formData.append('document', this.uploadedFile);
        this.loadingForm = true;

        // Procesar datos del formulario
        this.serverService.sendDataUsersApi(`/api/companies/${this.companyId}/suppliers/${this.currentUser.id}/handleDocument`, formData).subscribe({
          next: (response) => {
            if(response.data){
              this.loadingForm = false;
              this.uploadedFile = null;
              this.fileUpload.clear();
              this.currentUser = response.data;
              return this.messageService.add({
                severity:'success',
                summary: 'OK',
                detail: 'Documento de proveedor actualizado correctamente'
              })
            }
          },
          error: (err) => {
            this.loadingForm = false;
            console.error('Error al enviar formulario de datos', err);
          }
        });
      } else {
        this.loadingForm = false;
        return this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Debes seleccionar un archivo para subir'
        });
      }
    }
  }

  /**
   * Abrir documento de proveedor
   */
  openDocument(){
    window.open(this.currentUser.document, '_blank');
  }
}
