<p-dialog 
  [(visible)]="isModalOpen" 
  [modal]="false"
  [resizable]="false"
  [closable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <ng-container>
    <ng-template pTemplate="header">
      <div class="header-container">
        <p-avatar 
          image="../assets/images/CGarcia.png" 
          shape="circle" 
        />
        <span class="font-bold white-space-nowrap">
          Clara GarcIA
        </span>
        <i class="c-pointer pi pi-external-link" (click)="openDialogInNewWindow()"></i>
      </div>
    </ng-template>

    <!-- Contenido del modal -->
    <div #messagesContainer class="chat-messages">
      <div class="chat-messages__bot">
        <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
        <p >
          ¡Hola! Soy Clara GarcIA, tu consultora digital en GRC. 👩‍💼Estoy aquí para ayudarte a gestionar todo lo relacionado con Gobernanza, Riesgos y Cumplimiento. Mi objetivo es hacer que tus procesos sean más fáciles, eficientes y alineados con las normativas.
        </p>
      </div>

      <div class="chat-messages__bot">
        <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
        <p >
          🚀 ¿Qué puedo hacer por ti? Selecciona la opción deseada
          <button
            *ngFor="let suggestion of suggestions; let i = index"
            class="chat-messages__suggestion block"
            [ngClass]="{
              'button-error': suggestionError,
              'button-success': currentSuggestion.includes(i)
            }"
            type="button"
            (click)="setSuggestion(i)">
            {{ suggestion }}
          </button>
        </p>
      </div>

      <div class="chat-messages__bot">
        <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
        <div>
          <p>Si en algún momento necesitas más detalles o tienes una consulta específica, ¡no dudes en preguntarme! Estoy aquí para ser tu aliada en el camino hacia la excelencia en GRC.</p>
          <br>
          <p>¿Cómo puedo ayudarte hoy? 💡</p>
        </div>
      </div>

      <div *ngFor="let message of messages" [ngClass]="message.class" >
        <img *ngIf="message.class == 'chat-messages__bot'" src="../assets/images/CGarcia.png" alt="Avatar" class="chat-messages__avatar contentImg" />
        <p [innerHTML]="message.text"></p>
      </div>

      <div
        *ngIf="isLoading"
        class="chat-messages__bot chat-messages__bot-loading"
      >
        <div class="loader"></div>
      </div>
    </div>

    <!-- Footer para input de chat -->
    <ng-template pTemplate="footer">
      <div class="chat-input">
        <ion-item lines="none">
          <ion-textarea 
            [(ngModel)]="userMessage" 
            placeholder="Escribe un mensaje..."
          ></ion-textarea>
          <ion-icon name="send" (click)="sendMessage()"></ion-icon>
        </ion-item>
      </div>

      <p class="text-center my-3">
        <small>Clara GarcIA puede cometer errores. Considera verificar la información importante.</small>
      </p>
    </ng-template>
  </ng-container>
</p-dialog>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!isModalOpen" class="bottompos">
  <ion-fab-button (click)="openModal()">
    <img src="../assets/images/CGarcia.png" alt="Avatar" class="chat-avatar contentImg" />
    <!-- <ion-icon name="chatbubbles"></ion-icon> -->
  </ion-fab-button>
</ion-fab>
