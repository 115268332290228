import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { Indicator } from '../models/indicator';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  menu: MenuItem[] = [];
  @ViewChild('searchinput') searchInput!: ElementRef;
  @ViewChild('menubutton') menuButton!: ElementRef;
  searchActive: boolean = false;
  private apiUrl = 'http://localhost:8080';
  userInfo: any;
  indicators: Indicator[] = [];
  filteredItems: any[] = [];
  selectedItem: any;
  loadingIndicators: boolean = false;
  user: any;
  loadingUser: boolean = true;

  constructor(
    public layoutService: LayoutService,
    private http: HttpClient,
    private router: Router,
    private serverService: ServerService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getUserInfo();
  }

  /**
   * TODO: cambiar endpoint de indicadores
   * Obtener listado de indicadores
   */
  getIndicators() {
    this.loadingIndicators = true;
    this.serverService.getData('/api/getMenuIndicators/').subscribe({
      next: (response) => {
        this.indicators = response.data;
      },
      error: (err) => {
        console.error('Error al obtener los indicadores', err);
      },
      complete: () => {
        this.loadingIndicators = false;
      },
    });
  }

  /**
   * Obtener información de usuario en sesión
   */
  getUserInfo(){
    this.serverService.getDataUsersApi(`/api/user`).subscribe({
      next: (response) => {
        this.user = response;
        this.loadingUser = false;
      },
      error: (err) => {
        this.loadingUser = false;
        console.error('Error al obtener la información del usuario', err);
      }
    })
  }

  /**
   * Filtrado de indicadores en el buscador
   * @param query
   */
  filterItems(query: string) {
    if (query) {
      this.filteredItems = this.indicators.filter(
        (item) =>
          item.name && item.name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      this.filteredItems = [];
    }
  }

  /**
   * Manejador de opción seleccionada y redirección a pantalla del indicador según su tipo
   * @param item
   */
  selectItem(indicator: any) {
    this.selectedItem = indicator;
    this.searchActive = false;
    this.filteredItems = [];

    // Comprobar de qué tipo es el indicador antes de navegar
    this.serverService.getData('/api/indicators/' + indicator.id).subscribe({
      next: (response) => {
        if (!response.data) {
          return this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            key: 'tst',
            detail:
              'El indicador seleccionado no tiene datos, inténtelo con otro diferente',
          });
        } 

        const indicatorData = response.data;
        let route = '';

        if (indicatorData.indicator_configuration_id) {
          // Cuantitativo
          if (indicatorData.indicator_configuration?.special === 1) {
            route = 'base-indicator-special/'; // Especial
          } else {
            route = 'base-indicator/';
          }
        } else if (indicatorData['history_indicator_document']?.length > 0) {
          // Cualitativo
          route = 'base-qualitative/';
        } else if (indicatorData['grouped_indicators']?.length > 0) {
          // Cuantitativo agrupado
          route = 'base-quantitative-group/'; 
        } else {
          // Sin tipo
          return this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            key: 'tst',
            detail:
              'El indicador seleccionado no tiene tipo aún, inténtelo con otro diferente',
          });
        }

        // Redirigir según el tipo
        if (route) {
          this.router.navigate([route + indicator.id]);
        }
      },
      error: (err) => {
        console.error('Error al obtener datos del indicador', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'tst',
          detail: 'No se pudo cargar la información del indicador.',
        });
      }
    });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  activateSearch() {
    // Si están cargando ya los indicadores o no lo hemos cargado aún
    if (this.indicators.length === 0 && !this.loadingIndicators) {
      this.getIndicators();
    }

    this.searchActive = true;
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }

  deactivateSearch() {
    this.searchActive = false;
  }

  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    this.layoutService.onTabClose(item, index);
    event.preventDefault();
  }

  get layoutTheme(): string {
    return this.layoutService.config().layoutTheme;
  }

  get colorScheme(): string {
    return this.layoutService.config().colorScheme;
  }

  get logo(): string {
    const path = 'assets/layout/images/icloudcompliancelogo-';
    const logo =
      this.layoutTheme === 'primaryColor' &&
      !(this.layoutService.config().theme == 'yellow')
        ? 'light.png'
        : this.colorScheme === 'light'
        ? 'dark.png'
        : 'light.png';
    return path + logo;
  }

  get tabs(): MenuItem[] {
    return this.layoutService.tabs;
  }

  logout() {
    this.postLogout()
      .pipe(
        map((data) => {
          window.location.href = environment.usersURL + '/login';
          return data;
        }),
        catchError((error) => {
          console.error('Error!', error);
          return throwError(error);
        })
      )
      .subscribe();
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  postLogout(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });
    return this.http.post(`${environment.usersApiURL}/api/logout`, null, {
      headers: headers,
    });
  }

  goToUsers() {
    window.location.href = environment.usersURL;
  }
}
